import React, { useState, useEffect, useRef } from "react";
import "./card.css";
import ModalPrograms from "../ModalPrograms";

import collegeI from "../../img/pages/ExplorePrograms/college.png";
import collegeLogo from "../../img/pages/ExplorePrograms/collehe-logo.png";

import logoLevel from "../../icon/card/level.png";
import logoDestinationl from "../../icon/card/Destination.png";
import logoLanguage from "../../icon/card/Language.png";
import logoSector from "../../icon/card/Sector.png";
import logoTake from "../../icon/card/Take.png";


import logoDuration from "../../icon/card/Duration.png";

const ImgPath = process.env.REACT_APP_IMg_URL;

export const Card = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalItem, setModalItem] = useState(null);

  const openModal = (item) => {
    setModalItem(item);

    setIsModalVisible(true);
  };

  const closeModal = (item) => {
    setIsModalVisible(false);
    setModalItem(null);
  };

  const renderLevel = () => {
    if (props.Name?.level === "PG") {
      return "Master";
    } else if (props.Name?.level === "UG") {
      return "Bachelor";
    } else if (props.Name?.level === "Doctorate") {
      return "Doctorate";
    } else {
      return "Unknown Level";
    }
  };
  const lang =   props.Name?.language.replace('#', ', ');



  return (
    <div className="flex sm:justify-end">
      <div className="card-college">
        <div>
          <img
            className="college-img"
            src={ImgPath + props.Name?.main_image}
            alt=""
          />
        </div>
        <div className="flex flex-col h-full sm:justify-between card-body">
          <div className="flex flex-row justify-between pt-3 sm:pt-0">
            <div className="college-logo">
              <img src={ImgPath + props.Name?.image} alt="" />
            </div>
            <div>
              <button
                className="btn btn-primary-outline "
                onClick={() => openModal(props.Name)}
              >
                Enquiry Now
              </button>
            </div>
          </div>
          <div className="pt-3 sm:pt-0">
            <h4 className="card-head">
              {props.Name?.course_name} {props.Name?.sub_subject}
            </h4>
            <p className="text-sm text-[#87123C] font-semibold">
              {props.Name?.university}
            </p>
          </div>
          <div className="pt-1 pb-2 sm:pt-0">
            <div className="grid sm:grid-cols-[25%_50%_25%]  gap-1 items-start ">
              <div className="flex items-center gap-2 mt-1 sm:mt-0">
                <div>
                  <img src={logoLevel} alt="" />
                </div>
                <div>
                  <p className="text-xs text-[#00012F] ">Level of education</p>
                  <h6 className="text-sm font-semibold">{renderLevel()} </h6>
                </div>
              </div>
              <div className="flex items-center gap-2 mt-1 sm:mt-0">
                <div>
                  <img src={logoDestinationl} alt="" />
                </div>
                <div>
                  <p className="text-xs text-[#00012F] ">Destination</p>
                  <h6 className="text-sm font-semibold">
                    {props.Name?.destination}
                  </h6>
                </div>
              </div>
              <div className="flex items-center gap-2 mt-1 sm:mt-0">
                <div>
                  <img src={logoSector} alt="" />
                </div>
                <div>
                  <p className="text-xs text-[#00012F] ">Sector</p>
                  <h6 className="text-sm font-semibold">{props.Name?.type}</h6>
                </div>
              </div>
            </div>
            <div className="grid sm:grid-cols-[25%_50%_25%] grid-cols-2  gap-1 items-start mt-1  ">
            <div className="flex items-center gap-2 ">
                <div>
                  <img src={logoDuration} alt="" />
                </div>
                <div>
                  <p className="text-xs text-[#00012F] ">Duration</p>
                  <h6 className="text-sm font-semibold">
                    {props.Name?.duration} Years
                  </h6>
                </div>
                
              </div>
              <div className="flex items-center gap-2">
                <div>
                  <img src={logoLanguage} alt="" />
                </div>
                <div>
                  <p className="text-xs text-[#00012F] ">Language </p>
                  <h6 className="text-sm font-semibold">
                  {lang}
                  </h6>
                </div>
              </div>
           
              <div className="flex items-center gap-2 ">
                <div>
                  <img src={logoTake} alt="" />
                </div>
                <div>
                  <p className="text-xs text-[#00012F] ">In Take</p>
                  <h6 className="text-sm font-semibold">
                    {props.Name?.in_take}
                  </h6>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPrograms
        isVisible={isModalVisible}
        onClose={closeModal}
        item={modalItem}
      ></ModalPrograms>
    </div>
  );
};
