import React from "react";
import Slider from "react-slick";

import studentIcon from "../icon/migrate/student.png";
import BlueCardIcon from "../icon/migrate/BlueCard.png";
import FamilyReunIcon from "../icon/migrate/FamilyReunion.png";
import JobSeekerIcon from "../icon/migrate/JobSeeker.png";
import SkilledWorkerIcon from "../icon/migrate/SkilledWorker.png";
import LanguageVisa from "../icon/migrate/LanguageVisa.png";
import { IconSlider } from "../components/IconSlider";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MigrateBgOne from "../img/1.png";
import MigrateBgTwo from "../img/2.png";

export const HomeDestinations = ({ destinationData }) => {
  const iconArray = destinationData?.icon.split("#");
  const ImgPath = process.env.REACT_APP_IMg_URL;

  const iconNameArray = destinationData?.icon_name.split("#");
  const iconDetailsArray = destinationData?.pop_text.split("#");


  const iconPairs = iconArray?.map((icon, index) => ({
    icon,
    iconName: iconNameArray[index],
    iconDetails: iconDetailsArray[index]
  }));

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };


  return (
    <div className="pt-0 main-section">
      <div className="grid grid-cols-1 lg:gap-4 lg:grid-cols-2">
        <div className="">
          <div className="top-0 right-0 z-30 ">
            <img src={ImgPath + destinationData?.home_image} alt="" />
          </div>
        </div>
        <div className="flex flex-col justify-center sm:px-11 bg-country mt-9">
          <h1 className="text-center section-head sm:text-start">
            {destinationData?.main_head}
          </h1>
          <p className="text-center sm:text-start ">
            {destinationData?.sub_text}
          </p>

          <div className="text-center sm:text-start">
            <button className="mt-8 btn btn-primary">Know More</button>
          </div>
        </div>
      </div>

      {/* <div className="grid grid-cols-2 gap-3 pt-5 mt-10 sm:grid-cols-6 sm:gap-10 sm:mt-18">
        <div className="pink-card">
          <img src={studentIcon} alt="" />
          <small className="mt-2 text-base text-center text-white">
            Student Visa
          </small>
        </div>
        <div className="pink-card">
          <img src={LanguageVisa} alt="" />
          <small className="mt-2 text-base text-center text-white ">
            Language Visa
          </small>
        </div>
        <div className="pink-card">
          <img src={FamilyReunIcon} alt="" />
          <small className="mt-2 text-base text-center text-white ">
            Family Reunion Visa
          </small>
        </div>
        <div className="pink-card">
          <img src={SkilledWorkerIcon} alt="" />
          <small className="mt-2 text-base text-center text-white ">
            Skilled Worker Visa
          </small>
        </div>
        <div className="pink-card">
          <img src={JobSeekerIcon} alt="" />
          <small className="mt-2 text-base text-center text-white ">
            Job Seeker Visa
          </small>
        </div>
        <div className="pink-card">
          <img src={BlueCardIcon} alt="" />
          <small className="mt-2 text-base text-center text-white">
            Blue Card
          </small>
        </div>
      </div> */}
      {iconPairs?.icon == " " ? (
        <div></div>
      ) : (
        <IconSlider dataIcon={iconPairs} />
      )}
    </div>
  );
};
