import React, { useRef ,useEffect,useState } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';

import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";

import { Footer } from "../components/Footer";
import { SliderC } from "../components/Slider";
import { DestinationInfo } from "../components/DestinationInfo";
import Whatsapp from "../img/whatsapp.png";




import { SimpleFilter } from "../components/simpleFilter/SimpleFilter";




export const Destinations = () => {
  const navigate = useNavigate();
  const DivRefDes = useRef(null);

  const [courseData, setCourseData] = useState([]);
  const [univesity ,setUnivesity] = useState([]);
  const [destination,setDestination] = useState ([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [allData,setallData] = useState([])


  
  const apiUnivesity = process.env.REACT_APP_API_URL_UNIVERSITY;
  const apiCourse = process.env.REACT_APP_API_URL_COURSE;
  const apiDestination = process.env.REACT_APP_API_URL_DESTINATION;
  const apiAllData =  process.env.REACT_APP_API_URL_ALL;
  




  useEffect(() => {
    fetchCourse();
    fetchUniversity();
    fetchDestination();
    fetchAllData();
  }, []);
  const DivRef = useRef(null);

  
  useEffect(() => {
    if (DivRef.current) {
      DivRef.current.scrollIntoView({
          behavior: 'smooth'
      });
  }},[])

 



  

  const onCountrySelectdata  =(data) =>{
    setSelectedCountry(data)
  }

  const fetchCourse = async () => {
    try {
      const response = await axios.get(
        apiCourse
      );
      const CourseData = response.data;

      setCourseData(CourseData.reverse());
    } catch (error) {
    } finally {
    }
  };

  const fetchUniversity = async () => {
    try {
      const response = await axios.get(
        apiUnivesity
      );
      const CourseData = response.data;

      setUnivesity(CourseData.reverse());
    } catch (error) {
    } finally {
    }
  };
  const fetchDestination = async () => {
    try {
      const response = await axios.get(
        apiDestination
      );
      const DestinationData = response.data;

 
      
      setSelectedCountry(DestinationData[0])
      // setSelectedCountry(DestinationData[0])


      setDestination(DestinationData);
      // if (countryDetails === undefined || countryDetails === '' || countryDetails === null ) {
      //  }
      //  else{
      //   DivRefDes.current.scrollIntoView({
      //     behavior: "smooth",
      //   });
      //  }
    } catch (error) {
    } finally {
    }
   


  };

  const fetchAllData = async () => {
    try {
      const response = await axios.get(
        apiAllData
            );
      const DestinationData = response.data;

      setallData(DestinationData)
      
     
    } catch (error) {
    } finally {
    }
   


  };
  
  
  

  


  return (
    <div ref={DivRef}>
      <div className="page-banner bannerDestinations">
        <h4 className="banner-title">Explore Without Limits</h4>
      </div>
      <a
        className="Whats"
        href="https://wa.me/9188565665"
        target="_blank"
        tabindex="0"
      >
        <img className="" src={Whatsapp} alt="" />
      </a>

      <section className=" destinations">
        <div className="w-auto mb-20 sm:mb-0 main-section sm:w-3/4">
          <h1 className="text-center section-head sm:text-start" >Choose from list</h1>
      
        </div>
        <div className="pb-0 scroll-section"    >
          <SliderC countries={destination} onCountrySelect={onCountrySelectdata} design = {true}  />
        </div>
      </section >
      <div 
       >
      {selectedCountry &&  <DestinationInfo country={selectedCountry} dataCourse={courseData} dataUnivesity={univesity} allData={allData}   /> }
      </div>


      {/* <SimpleFilter dataCourse={courseData} dataUnivesity={univesity}  /> */}
      <Footer></Footer>

    </div>
  );
};
