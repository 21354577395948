import "./App.css";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import { Home } from "./pages/Home";
import { OurServices } from "./pages/OurServices";
import { ExplorePrograms } from "./pages/ExplorePrograms";
import { ContactUs } from "./pages/ContactUs";
import { Blogs } from "./pages/Blogs";
import { AboutUs } from "./pages/AboutUs";
import { Destinations } from "./pages/Destinations";
import {Career} from "./pages/Career"
import {PrivacyPolicy} from "./pages/PrivacyPolicy";
import {TermsConditions} from "./pages/Terms&Conditions"

import { NavBar } from "./components/NavBar";

function App() {
  return (
    <div>
      <Router>
        <div className="relative z-[100] ">
          <NavBar />
        </div>
        <div className="pages">
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/OurServices" element={<OurServices />}></Route>
            <Route
              path="/Explore-programs"
              element={<ExplorePrograms />}
            ></Route>
            <Route path="/Contact-us" element={<ContactUs />}></Route>
            <Route path="/Blogs" element={<Blogs />}></Route>
            <Route path="/About-us" element={<AboutUs />}></Route>
            {/* <Route path="/Destinations" element={<Destinations />}></Route> */}
            <Route path="/Career" element={<Career/>}></Route>

            <Route path="/Destinations" element={<Destinations />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/terms&Condition" element={<TermsConditions />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
