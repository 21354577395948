export const removeDuplicates = (options) => {
    const uniqueOptions = [];
    const seenValues = new Set();
  
    options.forEach((option) => {
      if (!seenValues.has(option.country)) {
        uniqueOptions.push(option);
        seenValues.add(option.country);
      }
    });
  
    return uniqueOptions;
  };

  export   const removeDuplicatesUniversity = (options) => {
    const uniqueOptions = [];
    const seenValues = new Set();
  
    options.forEach((option) => {
      if (!seenValues.has(option.university)) {
        uniqueOptions.push(option);
        seenValues.add(option.university);
      }
    });
  
    return uniqueOptions;
  };

  export   const removeDuplicatesLanguages = (options) => {
    const uniqueOptions = [];
    const seenValues = new Set();
    
    const allLanguages = options.flatMap(course => course.language.split('#'));


  
  allLanguages.forEach((option) => {



        
      if (!seenValues.has(option)) {
        uniqueOptions.push(option);
        seenValues.add(option);
      }
    });
  
    return uniqueOptions;
  };

   

  export   const removeDuplicatesCourse = (options) => {
    const uniqueOptions = [];
    const seenValues = new Set();
  
    options.forEach((option) => {
      if (!seenValues.has(option.stream)) {
        uniqueOptions.push(option);
        seenValues.add(option.stream);
      }
    });
  
    return uniqueOptions;
  };

  export   const removeSubDuplicatesCourse = (options) => {
    const uniqueOptions = [];
    const seenValues = new Set();
  
    options.forEach((option) => {
      if (!seenValues.has(option.sub_subject)) {
        uniqueOptions.push(option);
        seenValues.add(option.sub_subject);
      }
    });
  
    return uniqueOptions;
  };