import React, { useRef, useEffect, useState } from "react";
import Checkbox from "../Checkbox";

import { removeDuplicatesLanguages } from "../../../utils/removeDuplicates";

import "../input.css";

export const Languages = ({ options, onChange, country, onLanguages }) => {
  const [checkboxes, setCheckboxes] = useState([]);
  const [selectedKey, setSelectedKey] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);


  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const uniqueOptions = removeDuplicatesLanguages(options);
    const languageObjects = [...new Set(uniqueOptions)].map((language) => ({
      label: language,
      checked: true,
    }));
    setCheckboxes(languageObjects);
    onLanguages(languageObjects);
  }, [options]);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);

    };
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(()=>{
    if (screenWidth < 1024){
      setIsOpen(!isOpen);
    } 
  },[screenWidth])

  const handleCheckboxChange = (label, checked, key) => {
    const updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.label === label ? { ...checkbox, checked: checked } : checkbox
    );

    setCheckboxes(updatedCheckboxes);
    const filteredKey = updatedCheckboxes.filter(
      (checkbox) => checkbox.checked
    );
    setSelectedKey(filteredKey);
    onLanguages(filteredKey);
  };

  return (
    <div className="">



      <div>
      <div onClick={toggleAccordion} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', borderBottom: isOpen ? '1px solid #cccccc ' : '0px' }} className=" filter-subtitle">
      <h6 > Languages</h6>
        <div style={{ marginLeft: 'auto', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </div>
      {isOpen && <div>
      <div className="filter-inputs">
          {checkboxes.map((checkbox) => (
            <Checkbox
              label={checkbox.label}
              checked={checkbox.checked}
              onChange={handleCheckboxChange}
            />
          ))}
      </div></div>}
    </div>




      
    </div>
  );
};
