import React, { useState, useEffect } from "react";
import "./Slider.css";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const SliderC = ({ countries, onCountrySelect, selectedCountry, design }) => {
  // console.log("onCountrySelect",onCountrySelect)

  const [desigCard,setDesigCard] = useState(design)
  
  const ImgPath = process.env.REACT_APP_IMg_URL;

  const settings = {
    dots: false,
    slidesToScroll: 1,
    infinite: true,
    variableWidth: true,
    Speed: 2000,
    arrows: true,
    responsive: [
      // {
      //   breakpoint: 768, // tablet breakpoint
      //   settings: {
      //     slidesToScroll: 1,
      //     infinite: true,
      //     variableWidth: true,
      //   }
      // },
     

      {
        breakpoint: 480, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          variableWidth: false,
        },
      },
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          variableWidth: false,
        },
      },
     
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        {countries.map((country) => (
          <div className={({ isActive }) => (isActive ? "active" : "")}>
            <div
              className="card card-products card-products-one"
              onClick={() => onCountrySelect(country)}
            >
              <div className="relative ">
                <img src={ImgPath + country.main_image} alt="" className={design ? "card-Width" : ""}/>
               
                <div className="absolute inset-0 flex items-center justify-center text-white bg-black bg-opacity-40 ovely-card">
                <h5 className="absolute text-xl font-bold text-white bottom-3 left-3">
                  {country.country}
                </h5>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* <div>
          <div className="card card-products card-products-one">
            <div className="relative ">
              <img src={placeTwo} alt="" />
              <h5 className="absolute text-xl font-bold text-white bottom-3 left-3">
                Germany
              </h5>
            </div>
          </div>
        </div>
        <div>
          <div className="card card-products card-products-one">
            <div className="relative ">
              <img src={placeThree} alt="" />
              <h5 className="absolute text-xl font-bold text-white bottom-3 left-3">
                Germany
              </h5>
            </div>
          </div>
        </div>
        <div>
          <div className="card card-products card-products-one">
            <div className="relative ">
              <img src={placeFour} alt="" />
              <h5 className="absolute text-xl font-bold text-white bottom-3 left-3">
                Germany
              </h5>
            </div>
          </div>
        </div>
        <div>
          <div className="card card-products card-products-one">
            <div className="relative ">
              <img src={placeThree} alt="" />
              <h5 className="absolute text-xl font-bold text-white bottom-3 left-3">
                Germany
              </h5>
            </div>
          </div>
        </div> */}
      </Slider>
    </div>
  );
};
