import React, { useState, useEffect } from "react";
import axios from "axios";

import logoLevel from "../icon/card/level.png";
import logoDestinationl from "../icon/card/Destination.png";
import logoLanguage from "../icon/card/Language.png";
import logoSector from "../icon/card/Sector.png";
import logoDuration from "../icon/card/Duration.png";
import logoTake from "../icon/card/Take.png";


const ModalPrograms = ({ isVisible, onClose, children, item }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    language: "",
    university: "",
  });

  const ImgPath = process.env.REACT_APP_IMg_URL;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  if (!isVisible) return null;

  const handleClose = (e) => {
    if (e.target.id == "wrapper") onClose();
  };
  const renderLevel = () => {
    if (item?.level === "PG") {
      return "Master";
    } else if (item?.level === "UG") {
      return "Bachelor";
    } else if (item?.level === "Doctorate") {
      return "Doctorate";
    } else {
      return "Unknown Level";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const applyData = {
      data: formData,
      university: item?.course_name,
      course: item?.course_name + item?.sub_subject,
    };

    try {
      const res = await axios.post("/enquiry.php", applyData);
      onClose();
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };
  const lang =   item?.language.replace('#', ', ');


  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-25 backdrop-blur-sm z-[101]"
      onClick={handleClose}
      id="wrapper"
    >
      <div className="w-[85vw]  flex flex-col h-auto">
        <div className="mt-0 modal-body">
          <div
            className="relative mt-0 card-contact card-contact-full"
            style={{ marginTop: 0 }}
          >
            <div className="absolute top-3 right-4">
              <button
                className="text-3xl text-black place-self-end close-btn"
                onClick={() => onClose()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 22 22"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-[70%_30%] sm:gap-6 sm:pb-4">
              <div className="overflow-x-hidden overflow-y-auto h-[84vh]">
                <div className="flex flex-col items-center justify-between">
                  <div className="flex gap-2 border-[#ebebeb] border-b pb-3 w-full flex-col sm:flex-row ">
                    <div
                      className="college-logo"
                      style={{ paddingBottom: "0px", paddingTop: "0px" }}
                    >
                      <img src={ImgPath + item?.image} alt="" width="40px" />
                    </div>
                    <div>
                      <h3 className="text-lg font-medium lg:font-bold leading-[1]">
                        {item?.course_name} {item?.sub_subject}
                      </h3>
                      <p className="text-sm text-[#87123C] font-semibold">
                        {item?.university}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="pb-0 sm:pb-3 sm:pt-5 ">
                    <div className="pb-2 ">
                      <div className="grid grid-cols-[35%_65%] sm:grid-cols-[25%_50%_25%] gap-1 items-start sm:mt-0 mt-3   ">
                        <div className="flex items-center gap-2">
                          <div>
                            <img
                              src={logoLevel}
                              alt=""
                              className="max-w-[20px]"
                            />
                          </div>
                          <div>
                            <p className="text-xs text-[#00012F] ">
                              Level of education
                            </p>
                            <h6 className="text-sm font-semibold">
                              {renderLevel()}
                            </h6>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <div>
                            <img
                              src={logoDestinationl}
                              alt=""
                              className="max-w-[20px]"
                            />
                          </div>
                          <div>
                            <p className="text-xs text-[#00012F] ">
                              Destination
                            </p>
                            <h6 className="text-sm font-semibold">
                              {item?.destination}
                            </h6>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <div>
                            <img
                              src={logoSector}
                              alt=""
                              className="max-w-[20px]"
                            />
                          </div>
                          <div>
                            <p className="text-xs text-[#00012F] ">Sector</p>
                            <h6 className="text-sm font-semibold">
                              {item?.type}
                            </h6>
                          </div>
                        </div>
                       
                        <div className="flex items-center gap-2">
                          <div>
                            <img
                              src={logoDuration}
                              alt=""
                              className="max-w-[20px]"
                            />
                          </div>
                          <div>
                            <p className="text-xs text-[#00012F] ">Duration</p>
                            <h6 className="text-sm font-semibold">
                              {item?.duration} Years
                            </h6>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <div>
                            <img
                              src={logoLanguage}
                              alt=""
                              className="max-w-[20px]"
                            />
                          </div>
                          <div>
                            <p className="text-xs text-[#00012F] ">Language </p>
                            <h6 className="text-sm font-semibold">
                              {lang}
                            </h6>
                          </div>
                        </div>
                        <div className="flex items-center gap-2 ">
                          <div>
                            <img src={logoTake} alt="" />
                          </div>
                          <div>
                            <p className="text-xs text-[#00012F] ">Intake</p>
                            <h6 className="text-sm font-semibold">
                              {item?.in_take}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>{item?.info}</p>
                  </div>
                </div>
              </div>
              <div>
                <form onSubmit={handleSubmit} className="mt-2 ">
                  <div className="grid grid-cols-1 gap-2 mt-0 sm:mt-3 lg:grid-cols-1 sm:pr-[30px] items-center">
                    <div className="mt-3">
                      <label className="block mb-1 ">Name</label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-md"
                        placeholder="Enter Name"
                      />
                    </div>
                    <div className=" sm:mt-0">
                      <label className="block mb-1 ">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-md"
                        placeholder="Enter Email"
                      />
                    </div>
                    <div>
                      <label className="block mb-1 ">Phone</label>
                      <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-md"
                        placeholder="Enter Phone"
                      />
                    </div>
                    <input type="hidden" value={item?.university} />
                    <input
                      type="hidden"
                      value={item?.course_name}
                      onChange={handleChange}
                    />

                    <div className="mt-4">
                      <button className="btn btn-primary" type="submit">
                        Apply Now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPrograms;
