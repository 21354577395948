import React from 'react';

const Checkbox = ({ label, checked, onChange }) => {
  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    onChange(label, isChecked); // Pass the label and checked state to the parent component
  };

  return (
<div className="flex items-center flex-cols">
<label className="relative flex items-center p-2 rounded-full cursor-pointer" htmlFor="check">
  <input
  id={label}
          type="checkbox"
          checked={checked}
          onChange={handleCheckboxChange}
          className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"

        />
        <span
      className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
        stroke="currentColor" stroke-width="1">
        <path fill-rule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clip-rule="evenodd"></path>
      </svg>
      </span>
      </label>
  <label className="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor="check"  key={label}
              for={label} >
  
        {label}
      </label>
    </div>
  );
};

export default Checkbox;