import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { formatDistanceToNow, parse } from "date-fns";
import DateAgo from "../utils/DateAgo";

import ModalCareer from "../components/ModalCareer";
import FileUploadModal from "../components/test-demo";

import { Footer } from "../components/Footer";

import IconLocation from "../icon/location.png";
import IconBag from "../icon/bag.png";
import SalaryIcon from "../icon/money.png";
import InfoIcon from "../icon/note.png";
import Whatsapp from "../img/whatsapp.png";


export const Career = () => {
  const apiCareer = process.env.REACT_APP_API_URL_CAREER;
  const [careerData, setCareerData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalItem, setModalItem] = useState(null);


  const openModal = (item) => {
    setModalItem(item);

    setIsModalVisible(true);
  };

  const closeModal = (item) => {
    setIsModalVisible(false);
    setModalItem(null);

  };


  const DivRef = useRef(null);
  useEffect(() => {
    if (DivRef.current) {
      DivRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, []);

  useEffect(() => {
    fetchCareer();
  }, []);

  const fetchCareer = async () => {
    try {
      const response = await axios.get(apiCareer);
      const CareerData = response.data;

      setCareerData(CareerData.reverse());
    } catch (error) {
    } finally {
    }
  };

  return (
    <div ref={DivRef}>
      <div className="page-banner bannerCareer">
        <h4 className="banner-title">Dream your Career</h4>
      </div>
      <a
        className="Whats"
        href="https://wa.me/9188565665"
        target="_blank"
        tabindex="0"
      >
        <img className="" src={Whatsapp} alt="" />
      </a>

      <div className="main-section migrate">
        <div className="px-0 mx-auto sm:px-4 max-w-7xl lg:px-8">
          <div className="flex items-center justify-between mb-4">
            <div className="text-2xl text-[#424242] font-bold">Career</div>
            <span className="   text-[#424242] text-base ">
              <span className="font-bold "> {careerData.length}</span>
              <span className="font-medium "> Jobs</span>
            </span>
          </div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {careerData.map((item, index) => (
              <div className="max-w-md mx-auto overflow-hidden bg-white rounded-xl md:max-w-2xl">
                <div className="flex flex-col justify-between h-full p-4 sm:p-6">
                  <div>
                    <div className="flex justify-between mb-2">
                      <h5 className=" text-2xl font-bold text-[#00034d]  ">
                        {item.name}
                      </h5>
                      <div>
                        <button className="px-6 py-2 text-xs btn btn-primary-outline "  onClick={() => openModal(item)} >
                          Apply
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-2 mb-3 ">
                      <span className="flex gap-2 sm:mr-5">
                        <img className="object-none" src={IconBag} alt="" />
                        <small className="text-[#717B9E] font-medium">
                          {item.experience}
                        </small>
                      </span>
                      <span className="flex gap-2 sm:mr-5">
                        <img className="object-none" src={SalaryIcon} alt="" />
                        <small className="text-[#717B9E] font-medium">
                          {item.salary}
                        </small>
                      </span>
                      <span className="flex gap-2 sm:mr-5">
                        <img
                          className="object-none"
                          src={IconLocation}
                          alt=""
                        />
                        <small className="text-[#717B9E] font-medium">
                          {item.location}
                        </small>
                      </span>
                    </div>
                    <div className="flex gap-2">
                      <span className="flex items-start gap-2 sm:mr-5">
                        <img className="object-none" src={InfoIcon} alt="" />
                        <small className="text-[#717B9E] font-medium">
                          {item.info}
                        </small>
                      </span>
                    </div>

                    <p className="mt-4 text-base text-gray-600">{item.skills}</p>
                  </div>
                  <DateAgo dateString={item.created_on} />
                </div>
              </div>
            ))}
          </div>

          <div></div>
        </div>
      </div>
      <ModalCareer isVisible={isModalVisible} onClose={closeModal} item={modalItem}>
      </ModalCareer>
      {/* <FileUploadModal isVisible={isModalVisible} onClose={closeModal} item={modalItem}/> */}
      <Footer />
    </div>
  );
};
