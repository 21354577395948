import React, { useRef, useEffect, useState } from "react";

import { removeDuplicatesCourse } from "../../../utils/removeDuplicates";
import {removeSubDuplicatesCourse} from "../../../utils/removeDuplicates";
import DropdownLight from "../../MultiSelectDropdownLight";

export const CourseInput = ({ options, onChange, country, onCourse, levels }) => {
  const [selectedValue, setSelectedValue] = useState({course: '',
    subCourse: ''
  });
  const [LevelFilterDatas,setLevelFilterDatas] =useState ([])
  const [selectedsubCourse, setSelectedSubCourse] = useState("");

  const [subCourse, setSubCourse] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);


  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);

    };
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(()=>{
    const filteredCourses = options.filter(item => levels.includes(item.level))
    setLevelFilterDatas(filteredCourses)

  },[options,levels])
  useEffect(()=>{
    if (screenWidth < 1024){
      setIsOpen(!isOpen);
    } 
  },[screenWidth])



  const handleCourseChange = (event) => {
    const courseData = event.target.value;

     
    setSelectedValue({ ...selectedValue, course: event.target.value , subCourse: ''  });
    const filteredSubCourses = LevelFilterDatas.filter(
        ({stream}) => stream === courseData
      );
      setSubCourse(filteredSubCourses);
      setSelectedSubCourse('')
      onCourse({ ...selectedValue, course: event.target.value , subCourse: ''   })



  };
  const handlesubCourseChange = (event) => {
    const course = event.target.value;
    setSelectedValue({ ...selectedValue, subCourse: event.target.value })
    onCourse({ ...selectedValue, subCourse: event.target.value })


    
  };

  const uniqueOptions = removeDuplicatesCourse(LevelFilterDatas);
  const uniquesubOptions = removeSubDuplicatesCourse(subCourse);
  // const subCourseNameArray = uniquesubOptions?.sub_subject?.split(",");
  
  // console.log("subCourseNameArray",subCourseNameArray)


  return (
    <div >
      <div>
      <div onClick={toggleAccordion} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center',borderBottom: isOpen ? '1px solid #cccccc ' : '0px' }}  className="filter-subtitle">
      <h6> Course Stream</h6>
        <div style={{ marginLeft: 'auto', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </div>
      {isOpen && <div>  <from className="flex flex-col space-y-2 filter-inputs">
        <div className="items-center ">
          <div>
            <label
              htmlFor="stream"
              className="block text-sm font-medium text-[#000000]"
            >
              Select course
            </label>
            <select
              id="stream"
              className="text-base rounded-xl  border block w-full p-1 bg-[#ffffff]  border-[#000000] placeholder-gray-400 text-black"
              value={selectedValue.course}
              onChange={handleCourseChange}
            >
              <option value="" >
                Select an option
              </option>

              {uniqueOptions?.map(({ stream }) => (
                <option value={stream}>
                  {stream}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="mt-3">
            <label
              htmlFor="stream"
              className="block text-sm font-medium text-[#000000]"
            >
              Select course sub stream
            </label>
            <select
              id="stream"
              className="text-base rounded-xl border block w-full p-1 bg-[#ffffff]  border-[#000000] placeholder-gray-400 text-black"
              value={selectedValue.subCourse}
              onChange={handlesubCourseChange}
              disabled={selectedValue.course == !selectedValue.course}

            >
              <option value="">
                Select an option
              </option>

              {uniquesubOptions?.map(({ sub_subject }) => (
                <option key={sub_subject} value={sub_subject}>
                  {sub_subject}
                </option>
              ))}
            </select>
          </div> */}
                 <label
              htmlFor="stream"
              className="block text-sm font-medium text-[#000000] mt-3"
            >
              Select course sub stream
            </label>
          
          <DropdownLight  dataCourse={uniquesubOptions}  />
        
        </div>
      </from></div>}
    </div>
    </div>
  );
};
