import React, { useRef, useEffect, useState } from "react";
import { Univesity } from "./Univesity";
import { Country } from "./Country";
import { Sector } from "./Sector";
import { Level } from "./Level";
import { Languages } from "./Languages";
import { CourseInput } from "./Course";

import "../input.css";

export const SideBar = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const [searchFilterData, setSearchFilterData] = useState({});

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedUniversity, setSelectedUniversity] = useState("");
  const [selectedSector, setSelectedSector] = useState([
    "government",
    "private",
  ]);
  const [selectedLevel, setSelectedLevel] = useState(["PG", "UG", "Doctorate"]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState({
    course: "",
    subCourse: "",
  });
  const [clear, setClear] = useState("");

  const [filteredDataCountry, setFilteredDataCountry] = useState(props.allData);

  const [filteredData, setFilteredData] = useState(props.allData);

  useEffect(() => {
    setSearchFilterData(props.searchFilterData);
  }, [props.searchFilterData]);

  useEffect(() => {
    setSearchFilterData(props.searchFilterData);

    const dataSearch = props.searchFilterData;
    if (dataSearch === null) {
    } else {
      if (
        Object?.keys(dataSearch)?.length === 0 &&
        dataSearch?.constructor === Object
      ) {
      } else {
        setSelectedCountry(dataSearch.Destination);
        setSelectedLevel(dataSearch.Level);

        if (dataSearch.Stream == "all") {
          const newdatas = {
            course: "",
            subCourse: "",
          };
          setSelectedCourse(newdatas);
        } else {
          const newdata = {
            course: dataSearch.Stream,
            subCourse: dataSearch.SubStream,
          };
          setSelectedCourse(newdata);
        }

        switch (dataSearch.Sectore) {
          case "ALL":
            setSelectedSector(["government", "private"]);
            break;
          case "Government":
            setSelectedSector(["Government"]);
            break;
          case "Private":
            setSelectedSector(["Private"]);
            break;
          default:
            setSelectedSector(["government", "private"]);
        }
      }
    }

    //   if ((dataObject.length) === ) {
    //     console.log("The searchFilterData object is empty");

    // } else {
    //     console.log("The searchFilterData object is not empty");
    //     console.log(props.searchFilterData.Destination,"Destination")
    //     setSelectedCountry(dataObject.Destination)
    // }

    //   // setSelectedCountry(props.searchFilterData.Destinationn);
    // }
    //   if (Object.keys(dataSearch).length === 0 && dataSearch.constructor === Object) {
    //     // Object is empty
    //     console.log("Object is empty");
    // } else {
    //     // Object is not empty
    //       // setSelectedCountry(searchFilterData.Destination)
    //       console.log("Object  not is empty");

    // }
  }, [props.searchFilterData]);

  const handleDataCountry = (childData) => {
    setSelectedCountry(childData);
  };
  const handleDataUnivesity = (Data) => {
    setSelectedUniversity(Data);
  };
  const handleDataSector = (data) => {
    setSelectedSector(data);
  };
  const handleDataLevel = (data) => {
    setSelectedLevel(data);
  };
  const handleDataLanguages = (data) => {
    setSelectedLanguage(data);
  };
  const handleDataCourse = (data) => {
    setSelectedCourse(data);
  };

  // useEffect(() => {
  //   setFilteredData(props.allData);
  // }, []);

  const selectedLanguages = selectedLanguage
    .filter((lang) => lang.checked)
    .map((lang) => lang.label.toLowerCase());

  useEffect(() => {
    const filteredCountries = props.allData.filter((data) => {
      const courseLanguages = data.language
        .split("#")
        .map((lang) => lang.trim().toLowerCase());
      return (
        (selectedCountry === "" || selectedCountry === data.country) &&
        (selectedUniversity === "" || selectedUniversity === data.university) &&
        (selectedSector.length === 0 || selectedSector.includes(data.type)) &&
        (selectedLevel.length === 0 || selectedLevel.includes(data.level)) &&
        (selectedCourse?.course === "" ||
          selectedCourse?.course === data.stream) &&
        (selectedCourse?.subCourse === "" ||
          selectedCourse?.subCourse === data.sub_subject) &&
        courseLanguages.some((lang) => selectedLanguages.includes(lang))
      );
    });

    setFilteredData(filteredCountries);
    props.onFilteredData(filteredCountries);
  }, [
    selectedCountry,
    selectedUniversity,
    selectedSector,
    selectedLevel,
    selectedLanguage,
    selectedCourse,
  ]);

  // const clearHandler = ( ) =>{
  //   setSelectedCountry("")
  //   setSelectedUniversity("")
  //   setSelectedSector([
  //     "government",
  //     "private",
  //   ])
  //   setSelectedLevel(["PG", "UG", "Doctorate"])
  //   setSelectedLanguage([])
  //   setSelectedCourse({
  //     course: '',
  //     subCourse: '',
  //   })
  //   setFilteredData(props.allData);
  //   setClear()

  //   props.onFilteredData(props.allData);

  // }

  const handleRefresh = () => {
    window.location.reload();
  };
  return (
    <div className="filter-bar ">
      <div className="flex justify-between p-4">
        <h1 className=" text-[#00034D] font-medium  text-2xl ">Filters</h1>
        <button
          className=" text-[#87123C] font-semibold text-xs "
          onClick={handleRefresh}
        >
          Clear filters
        </button>
      </div>
      <Country
        options={props.allData}
        name="options"
        onCountry={handleDataCountry}
      />
      <Univesity
        options={props.allData}
        name="options"
        onUnivesity={handleDataUnivesity}
      />
      <Sector
        options={props.allData}
        name="options"
        onSector={handleDataSector}
      />
      <Level options={props.allData} name="Level" onLevel={handleDataLevel} />
      <CourseInput
        options={props.allData}
        name="Level"
        levels={selectedLevel}
        onCourse={handleDataCourse}
      />

      <Languages
        options={props.allData}
        name="Level"
        onLanguages={handleDataLanguages}
      />

      <div></div>
    </div>
  );
};
