import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

import "./parallax.css";

import character from "../img/banner/character-img.png";

export const Parallax = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });
  const backgroundTwoY = useTransform(scrollYProgress, [0, 5], ["0%", "120%"]);
  const backgroundThreeY = useTransform(
    scrollYProgress,
    [0, 2],
    ["0%", "100%"]
  );
  const backgroundFourY = useTransform(
    scrollYProgress,
    [0, 40],
    ["0%", "100%"]
  );

  const textY = useTransform(scrollYProgress, [0, 1], ["0%", "120%"]);
  const opacity1 = useTransform(scrollYProgress, [1, 0], [0, 1]);

  return (
    <div className="">
      <div
        className="relative grid w-full h-screen overflow-hidden place-items-center"
        ref={ref}
      >
        <motion.div
          style={{ y: textY, opacity: opacity1 }}
          className="absolute bottom-[-5px] sm:left-[30%]  z-30"
        >
          <img src={character} alt="" className="character-img " />
        </motion.div>
        <motion.div
          style={{ y: backgroundThreeY }}
          className="absolute inset-0 z-20 bgFour h-[113vh] sm:h-[106vh] xl:h-[100vh]"
        ></motion.div>
        <motion.div className="absolute inset-0 z-0 bgOne"></motion.div>
        <motion.div
          style={{ y: backgroundTwoY }}
          className="absolute inset-0 z-15 bgTwo"
        ></motion.div>

        <div className="absolute left-0 top-auto z-30 main-section bottom-12 xl:bottom-0">
          <h1 className="flex flex-col banner-head">
            <span>Step </span>
            <span> In To The</span> <span>Bright Future</span>
          </h1>
          <p className="w-auto mt-4 mb-6 text-lg text-white xl:mb-4 xl:w-2/4 ">
            Step into a bright and promising future with the Best Expat Service
            Provider in Europe. Your trusted expert for studying abroad in
            Germany
          </p>
        </div>
      </div>
    </div>
  );
};
