import React, { useRef, useEffect, useState } from "react";

import { removeDuplicatesUniversity } from "../../../utils/removeDuplicates";
import { AccordionFilter } from "../../AccordionFilter";

export const Univesity = ({ options, onChange, country, onUnivesity }) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onUnivesity(event.target.value);
  };
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);

    };
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(()=>{
    if (screenWidth < 1024){
      setIsOpen(!isOpen);
    } 
  },[screenWidth])

  const FAQ = [
    {
      title: "What are the entry requirements for German universities?",
      content:
        "Entry requirements vary depending on the program and university. Generally, you need a recognized secondary school leaving certificate, proficiency in German or English (depending on the language of instruction), and sometimes specific subject prerequisites.",
    },

  
 
  ];

  const uniqueOptions = removeDuplicatesUniversity(options);

  return (
    <div>

<div>
      <div onClick={toggleAccordion} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', borderBottom: isOpen ? '1px solid #cccccc ' : '0px'  }}className="filter-subtitle">
      <h6>University      </h6>
        <div style={{ marginLeft: 'auto', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </div>
      {isOpen && <div>  <from className="flex flex-col space-y-2 filter-inputs ">
        <div className="inline-flex items-center">
          <label
            className="relative flex items-center p-2 rounded-full cursor-pointer"
            htmlFor="html"
          >
            <input
              type="radio"
              name="university"
              value=""
              checked={selectedValue === ""}
              onChange={handleChange}
              className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
            />
            <span className="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3.5 w-3.5"
                viewBox="0 0 16 16"
                fill="currentColor"
              >
                <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
              </svg>
            </span>
          </label>

          <label
            className="mt-px font-light text-gray-700 cursor-pointer select-none"
            htmlFor="html"
          >
            All
          </label>
        </div>
        {uniqueOptions.map((option) => (
                  <div className="inline-flex items-center">

          <label key={option.university}  className="relative flex items-center p-2 rounded-full cursor-pointer"
          htmlFor="html">
            <input
              type="radio"
              name="university"
              id={option.university}
              value={option.university}
              checked={selectedValue === option.university}
              onChange={handleChange}
              className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
            />
                  <span className="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3.5 w-3.5"
                viewBox="0 0 16 16"
                fill="currentColor"
              >
                <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
              </svg>
            </span>
                      </label>

                      <label
              className="mt-px font-light text-gray-700 cursor-pointer select-none"
              htmlFor="html"
              key={option.university}
              for={option.university}
            >{option.university}</label>
          </div>
        ))}
      </from></div>}
    </div>




      
    


    
    </div>
  );
};
