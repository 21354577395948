import React, { useState, useEffect, useRef } from 'react';
import { Accordion } from "../components/Accordion";

import Accommodation from "../icon/features/Accommodation.png";
import AusbildungAssistance from "../icon/features/AusbildungAssistance.png";
import Insurance from "../icon/features/Insurance.png";
import LawyerSupport from "../icon/features/LawyerSupport.png";
import MigrateBgOne from "../img/about.png";
import ImgBground from "../img/earth.png";
import studentIcon from "../icon/migrate/student.png";
import LanguageVisa from "../icon/migrate/LanguageVisa.png";
import FamilyReunIcon from "../icon/migrate/FamilyReunion.png";
import SkilledWorkerIcon from "../icon/migrate/SkilledWorker.png";
import JobSeekerIcon from "../icon/migrate/JobSeeker.png";
import BlueCardIcon from "../icon/migrate/BlueCard.png";

import ExperienceIcon from "../icon/about/experience.png";
import FlagIcon from "../icon/about/flag.png";
import Succefull from "../icon/about/succeful.png";
import Visa from "../icon/about/visa.png";
import languageIcon from "../icon/about/language.png";
import MoneyIcon from "../icon/about/Money.png";
import AboutBg from "../img/about-1.png";

import TeamImg1 from "../img/team1.png";
import TeamImg2 from "../img/team2.png";
import TeamImg3 from "../img/team3.png";
import TeamImg4 from "../img/team5.png";
import TeamImg5 from "../img/team6.png";
import TeamImg6 from "../img/team4.png";
import globalIMg from "../img/global.png";
import GroupImg from "../img/group-Img.png";
import Expertise from "../icon/about/expertise.png";
import Personalized from "../icon/about/Personalized.png";
import Record from "../icon/about/Record.png"
import Network from "../icon/about/Network.png";
import Whatsapp from "../img/whatsapp.png";



import { Footer } from "../components/Footer";

export const AboutUs = () => {
  const FAQ = [
    {
      title: "What are the entry requirements for German universities?",
      content:
        "Entry requirements vary depending on the program and university. Generally, you need a recognized secondary school leaving certificate, proficiency in German or English (depending on the language of instruction), and sometimes specific subject prerequisites.",
    },
    {
      title: "Are there tuition fees for international students?",
      content:
        "Entry requirements vary depending on the program and university. Generally, you need a recognized secondary school leaving certificate, proficiency in German or English (depending on the language of instruction), and sometimes specific subject prerequisites.",
    },
    {
      title: "How do I apply for a program?",
      content:
        "Entry requirements vary depending on the program and university. Generally, you need a recognized secondary school leaving certificate, proficiency in German or English (depending on the language of instruction), and sometimes specific subject prerequisites.",
    },
    {
      title: "What scholarships are available for international students?",
      content:
        "Entry requirements vary depending on the program and university. Generally, you need a recognized secondary school leaving certificate, proficiency in German or English (depending on the language of instruction), and sometimes specific subject prerequisites.",
    },
    {
      title:
        "Are internships or practical experiences included in the curriculum?",
      content:
        "Entry requirements vary depending on the program and university. Generally, you need a recognized secondary school leaving certificate, proficiency in German or English (depending on the language of instruction), and sometimes specific subject prerequisites.",
    },
  ];
  const WhyLambegusData1 = [
    {
      title: "Personalized Counseling",
      content:
        "We are equipped with expert academic counselors who provide a one-on-one consultation to students and meticulously evaluate your profile and educational goals. We offer tailored advice on selecting the right course and destination of study and making the process of applying a stress-free experience.",
    },
    {
      title: "Visa Application Assistance & Visa Support",
      content:
        "Our dedicated team of experts makes sure that all the documents are accurate and up-to-date, this helps simplify the complex visa application process. Also, we will keep informed about the developments and the latest visa regulations and requirements, eventually leading to maximizing the success of your application. requirements vary depending on the program and university. Generally, you need a recognized secondary school leaving certificate, proficiency in German or English (depending on the language of instruction), and sometimes specific subject prerequisites.",
    },
    {
      title: "Language Preparation",
      content:
        "We prepare students to appear for globally standardized tests such as IELTS, GRE, GMAT, IELTS and TOEFL, and German, which is mandatory for European countries. Students can achieve their desired scores with our exceptional test preparation and language learning resources.",
    },
    {
      title: " Financial Planning",
      content:
        " Financial Guidance , Loan, blocked account supportOur services include helping students find the right scholarships, securing financial support, and managing budgets effectively. We offer loan assistance to meet your monetary needs and requirements in the host country.",
    },
    {
      title: "Pre-Departure Orientation",
      content:
        "Pre-Departure Orientation program is designed to equipstudents with the practical knowledge and skills needed for a smooth transition to life abroad. We provide comprehensive guidance on securing accommodation,understanding and adapting to new cultures, and meeting academic expectations. Our expert team ensures that students are well-prepared to navigate their new environment confidently, offering valuable insights and tips to help them settle in and thrive. With our thorough preparation, students can embark on their international journey well-informed and ready to make the most of their overseas experience.",
    },
    {
      title: " Post landing servicest",
      content:
        "Our dedicated team of experts makes sure that all the documents are accurate and  Post-Landing Services are designed to provide students with a seamless transition into their new life abroad. We offer a range of support services including airport pickup, assistance with securing accommodation, and help with opening  a bank account. Additionally, we provide insurance support and guidance for finding part-time employment to ensure financial stability. If needed, we assist with visa extension processes, even if the initial application was completed long ago, and offer support for those wishing to transfer to a different university. Our post-landing services are boundless, ensuring that students receive continuous support and comfort throughout their stay, making their international experience as smooth and successful as possible-to-date, this helps simplify the complex visa application process. Also, we will keep informed about the developments and the latest visa regulations and requirements, eventually leading to maximizing the success of your application. requirements vary depending on the program and university. Generally, you need a recognized secondary school leaving certificate, proficiency in German or English (depending on the language of instruction), and sometimes specific subject prerequisites.",
    },
    {
      title: "Extra Support ",
      content:
        " At Lambegus Abroad Consultancy Services, we offer extensive support to improve the quality of life for our clients abroad. We provide a dedicated point-to-point contact person in every country to assist you in various ways, including finding accommodation, securing part-time jobs, opening bank accounts, and obtaining abroad insurance. Additionally, we offer support for any necessary paperwork and provide access to legal assistance for any future needs.",
    },
  ];

  const DivRef = useRef(null);
  useEffect(() => {
    if (DivRef.current) {
      DivRef.current.scrollIntoView({
          behavior: 'smooth'
      });
  }},[])

  return (
    <div>
      <div className="page-banner bannerAboutUs" ref={DivRef}>
        <h4 className="banner-title">Success is a combination of dreams and passion.</h4>
      </div>
      <a
        className="Whats"
        href="https://wa.me/9188565665"
        target="_blank"
        tabindex="0"
      >
        <img className="" src={Whatsapp} alt="" />
      </a>

      <section className="relative grid mt-2 overflow-hidden laBg">
        <div className="main-section">
          <div className="grid grid-cols-1 sm:gap-4 sm:grid-cols-2">
            <div className="relative">
              <div className="top-0 right-0 z-30">
                <img src={AboutBg} alt="" className="migrateOne" />
              </div>
            </div>

            <div className="flex flex-col items-center justify-center xl:px-11 mt-9">
              <div className="xl:mb-4">
               
                <h1 className="text-center section-head sm:text-start">
                Explore. Educate. Empower.
                </h1>
              </div>
              <p className="text-center sm:text-start ">
              Lambegus is focused on empowering students to achieve global academic aspirations through extensive education and career consultancy services, providing them with immense support to navigate their journeys to top-class institutions all over the globe, ensuring that they undergo a smooth transition and embark on an enriching educational experience.               </p>
            </div>
          </div>
          <div>
            <h4 className="mt-5 mb-1 text-center sub-pg-title">
              Our Expertise
            </h4>
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-3 sm:grid-cols-2">
              <div className="white-card">
                <img src={ExperienceIcon} alt="" />
                <p className="text-lg font-medium">
                  9+ years of experience in abroad education management
                </p>
              </div>
              <div className="white-card ">
                <img src={Succefull} alt="" />
                <p className="text-lg">400+ successful student profiles</p>
              </div>
              <div className="white-card ">
                <img src={Visa} alt="" />
                <p className="text-lg">
                  Visa Assistance for Study and Vocation (Ausbildung)
                </p>
              </div>
              <div className="white-card ">
                <img src={MoneyIcon} alt="" />
                <p className="text-lg">Financial Assistance</p>
              </div>
              <div className="white-card ">
                <img src={languageIcon} alt="" />
                <p className="text-lg">Language Lessons (English and German)</p>
              </div>
              <div className="white-card ">
                <img src={FlagIcon} alt="" />
                <p className="text-lg">
                  Post-landing services such as accommodation, part-time jobs,
                  banking, insurance, legal support etc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative grid overflow-hidden bg-welcome">
        <div className="main-section">
          <div className="grid grid-cols-1 sm:gap-4 sm:grid-cols-[20%_80%]">
            <div className="relative">
              <div className="top-0 right-0 z-30">
                <img className="m-auto" src={ImgBground} alt="" />
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <h1 className="text-center section-head sm:text-start">
                <span className="font-light "> Our </span>{" "}
                <span className="text-[#E10F5A]">Mission</span>
              </h1>
              <p className="text-center sm:text-start ">
                At Lambegus, our goal is to empower students to make informed
                decisions about their academic ambitions abroad. We aim to
                facilitate their academic journey to globally recognized
                prestigious institutions all over the world. We strive to ensure
                a smooth transition to their host country and have an enriching
                educational experience.
              </p>
             
            </div>
          </div>
        </div>
      </section>
      <section className="relative grid overflow-hidden bg-welcomeTwo">
        <div className="main-section">
          <div className="grid grid-cols-1 sm:gap-4 sm:grid-cols-[75%_25%]">
            <div className="flex flex-col justify-center sm:px-11">
              <h1 className="text-center section-head sm:text-start">
                <span className="font-light "> Our</span>
                <span className="text-[#E10F5A] "> Vision </span>
              </h1>
              <p className="text-center sm:text-start ">
                We envision a world where every student gets the opportunity to
                access top-notch educational opportunities, irrespective of
                their background and where they come from. By offering
                customized consulting services, our efforts are to open doors to
                global education and career success.
              </p>
            </div>
            <div className="relative">
              <div className="top-0 right-0 z-30">
                <img src={globalIMg} alt="" className="migrateOne" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative grid mt-2 overflow-hidden laBg">
        <div className="main-section">
          <div className="text-center sm:pb-6">
            <h1 className="flex justify-center mb-6 text-center sm:gap-2 section-head">
              <span className="text-3xl thin-font sm:text-4xl "> Why </span>
              <span className="text-[#00034d] blod-font text-3xl sm:text-4xl"> Lambegus ? </span>
            </h1>
            <p className="w-10/12 m-auto text-center">
            From IELTS Coaching to pre-departure orientation, and post-landing services to finding the right scholarship opportunities and housing facilities, we are with you every step of the way offering customized guidance that resonates with your scholastic goals.

            </p>
          </div>
          <div className="grid items-center content-start grid-cols-1 bg-auto xl:grid-cols-[20%_80%] pt-8">
            <div className="relative hidden xl:block">
              <div className="top-0 right-0 z-30">
                <img src={GroupImg} alt="" className="migrateOne" />
              </div>
            </div>
            <div className="flex flex-col justify-center px-6 py-3 bg-white sm:px-11 sm:py-6 rounded-2xl">
              <Accordion item={WhyLambegusData1}></Accordion>
            </div>
          </div>
        </div>
      </section>
      <section className="relative grid mt-2 overflow-hidden laBg">
        <div className="main-section">
          <div className="mb-4">
            <h1 className="justify-center mb-10 text-center sm:flex sm:gap-2 section-head">
              <span className="text-3xl thin-font sm:text-4xl"> Why</span> <span className="text-3xl thin-font sm:text-4xl"> Choose </span>
              <span className="text-[#00034d] blod-font text-3xl sm:text-4xl"> Lambegus</span> <span className="text-[#00034d] blod-font text-3xl sm:text-4xl "> Abroad</span>
            </h1>
          </div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div className="white-card-icon">
              <div className="flex items-center gap-2">
                <img className="icon-bg" src={Expertise} alt="" />
                <h6 className="text-xl font-semibold">Expertise</h6>
              </div>
              <p>
                With experience and expertise spanning nine years, we possess a
                deep understanding of the intricate complexities involved with
                the admission process that comes when applying to international
                universities and academic institutions.
              </p>
            </div>
            <div className="white-card-icon">
              <div className="flex items-center gap-2">
                <img className="icon-bg" src={Personalized} alt="" />
                <h6 className="text-xl font-semibold">Personalized</h6>
              </div>
              <p>
              We provide customized guidance that aligns with the needs and educational goals of each student, thereby ensuring a personal and effective counseling experience
              </p>
            </div>
            <div className="white-card-icon">
              <div className="flex items-center gap-2">
                <img className="icon-bg" src={Record} alt="" />
                <h6 className="text-xl font-semibold">Proven Track Record</h6>
              </div>
              <p>
              Our successful track record of student placements in world-renowned educational institutions. And, the testimonials by our clients are a testament to our exceptional service. We take immense pride in being a part of the glory of our students.              </p>
            </div>
            <div className="white-card-icon">
              <div className="flex items-center gap-2">
                <img className="icon-bg" src={Network} alt="" />
                <h6 className="text-xl font-semibold">Global Network</h6>
              </div>
              <p>
              Our extensive, network of partner institutions and educational organizations worldwide allows us to offer students a wide variety of courses and opportunities.</p>            </div>
          </div>
        </div>
      </section>

      <section className=" bg-gray-50">
        <div className="main-section">
          <div className="flex flex-col items-center justify-between lg:flex-row md:mt-10">
            <div className="w-full lg:w-3/5 sm:pr-12">
              <h2 className=" text-5xl text-[#00034d] font-bold leading-[4rem] mb-6 text-center lg:text-left">
                Our Leading, Strong Team
              </h2>
              <p className="mb-8 text-lg text-center text-gray-500 xl:mb-16 lg:text-left ">
                Our team at Lambegus Abroad consists of dedicated professionals
                with extensive experience in the field of educational
                consultancy. We are passionate about helping students, and
                skilled Professional to succeed, and committed to providing the
                highest quality of service. Our counselors stay updated on the
                latest trends and developments in global education to offer
                accurate and relevant advice.
              </p>
              <div className='flex justify-center xl:block'>
              <button className=" btn btn-primary">Join our team</button>

              </div>

            </div>
            <div className="w-full mt-12 xl:mt-16 lg:w-2/5 lg:mt-0 max-lg:max-w-2xl">
              <div className="grid grid-cols-2 min-[450px]:grid-cols-2 md:grid-cols-3 gap-8">
                <img
                  src={TeamImg1}
                  alt="Team tailwind section"
                  className="w-44 h-56 rounded-2xl object-cover md:mt-20 mx-auto min-[450px]:mr-0"
                />
                <img
                  src={TeamImg2}
                  alt="Team tailwind section"
                  className="w-44 h-56 rounded-2xl object-cover mx-auto min-[450px]:ml-0 md:mx-auto"
                />
                <img
                  src={TeamImg3}
                  alt="Team tailwind section"
                  className="w-44 h-56 rounded-2xl object-cover md:mt-20 mx-auto min-[450px]:mr-0 md:ml-0"
                />
                <img
                  src={TeamImg4}
                  alt="Team tailwind section"
                  className="w-44 h-56 rounded-2xl object-cover mx-auto min-[450px]:ml-0 md:mr-0 md:ml-auto"
                />
                <img
                  src={TeamImg5}
                  alt="Team tailwind section"
                  className="w-44 h-56 rounded-2xl object-cover md:-mt-20 mx-auto min-[450px]:mr-0 md:mx-auto"
                />
                <img
                  src={TeamImg6}
                  alt="Team tailwind section"
                  className="w-44 h-56 rounded-2xl object-cover mx-auto min-[450px]:ml-0 md:mr-0"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
