import React from "react";
import { Footer } from "../components/Footer";

export const Blogs = () => {
  return (
    <div className="sm:mt-18">
      {/* <div className="page-banner bannerExplorePrograms">
        <h4 className="banner-title">
          Discover the perfect career path for you.
        </h4>
      </div> */}
      <section className="py-10 bg-white sm:py-16 lg:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
              Latest from blog
            </h2>
            <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis.
            </p>
          </div>

          <div className="grid max-w-md grid-cols-1 mx-auto mt-12 lg:max-w-full lg:mt-16 lg:grid-cols-3 gap-x-16 gap-y-12">
            <div>
              <a href="#" title="" className="block aspect-w-4 aspect-h-3">
                <img
                  className="object-cover w-full h-full rounded-2xl	"
                  src="https://cdn.rareblocks.xyz/collection/celebration/images/blog/1/blog-post-1.jpg"
                  alt=""
                />
              </a>
              <span className="inline-flex px-4 py-2 text-xs font-semibold   uppercase rounded-full text-rose-500 bg-rose-100 mt-9">
                Engineering
              </span>
              <p className="mt-6 text-xl font-semibold">
                <a href="#" title="" className="text-black">
=                  Lorem Ipsum is simply dummy text of the printing?
                </a>
              </p>
              <p className="mt-4 text-gray-600">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p>
              <div className="h-0 mt-6 mb-4 border-t-2 border-gray-200 border-dashed"></div>
              <span className="block text-sm font-bold   text-gray-500 uppercase">
                
                Martin Jones . June 12, 2021
              </span>
            </div>

            <div>
              <a href="#" title="" className="block aspect-w-4 aspect-h-3">
                <img
                  className="object-cover w-full h-full rounded-2xl	"
                  src="https://cdn.rareblocks.xyz/collection/celebration/images/blog/1/blog-post-2.jpg"
                  alt=""
                />
              </a>
              <span className="inline-flex px-4 py-2 text-xs font-semibold   uppercase rounded-full text-sky-500 bg-sky-100 mt-9">
=                Diploma
              </span>
              <p className="mt-6 text-xl font-semibold">
                <a href="#" title="" className="text-black">                  Why do we use it?
                </a>
              </p>
              <p className="mt-4 text-gray-600">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p>
              <div className="h-0 mt-6 mb-4 border-t-2 border-gray-200 border-dashed"></div>
              <span className="block text-sm font-bold   text-gray-500 uppercase">
                Martin Jones . June 12, 2021
              </span>
            </div>

            <div>
              <a href="#" title="" className="block aspect-w-4 aspect-h-3">
                <img
                  className="object-cover w-full h-full rounded-2xl	"
                  src="https://cdn.rareblocks.xyz/collection/celebration/images/blog/1/blog-post-3.jpg"
                  alt=""
                />
              </a>
              <span className="inline-flex px-4 py-2 text-xs font-semibold   uppercase rounded-full text-sky-500 bg-sky-100 mt-9">
                Diploma
              </span>
              <p className="mt-6 text-xl font-semibold">
                <a href="#" title="" className="text-black">
                  The standard chunk of Lorem Ipsum used since the 1500s.{" "}
                </a>
              </p>
              <p className="mt-4 text-gray-600">
                The standard chunk of Lorem Ipsum used since the 1500s is
                reproduced below for those interested.
              </p>
              <div className="h-0 mt-6 mb-4 border-t-2 border-gray-200 border-dashed"></div>
              <span className="block text-sm font-bold   text-gray-500 uppercase">
                Martin Jones . June 12, 2021{" "}
              </span>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
