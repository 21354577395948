import React, { useRef, useEffect, useState } from "react";
import { Parallax } from "../components/parallax";
import { motion, useScroll, useTransform } from "framer-motion";
import { Footer } from "../components/Footer";
import { Accordion } from "../components/Accordion";
import { SliderC } from "../components/Slider";
import axios from "axios";
import { SimpleFilter } from "../components/simpleFilter/SimpleFilter";
import { NavLink, useHistory } from "react-router-dom";
import { HomeDestinations } from "../components/HomeDestinations";
import FileUpload from "../components/test";
import { ripples } from "ldrs";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import ModalBook from "../components/BookModal";

import Whatsapp from "../img/whatsapp.png";

import WelcomeBgOne from "../img/welcome/1.png";
import WelcomeBgTwo from "../img/welcome/2.png";

import Accommodation from "../icon/features/Accommodation.png";
import AusbildungAssistance from "../icon/features/AusbildungAssistance.png";
import Insurance from "../icon/features/Insurance.png";
import LawyerSupport from "../icon/features/LawyerSupport.png";

import WelcomeUniversity from "../icon/welcome/university.png";
import WelcomeHouse from "../icon/welcome/house.png";
import WelcomeCap from "../icon/welcome/university-cap.png";
import WelcomeBooks from "../icon/welcome/books.png";

import languageOne from "../img/language/1.png";
import languageTwo from "../img/language/2.png";

export const Home = () => {
  const migrate = useRef(null);
  const navigate = useNavigate();

  const [courseData, setCourseData] = useState([]);
  const [univesity, setUnivesity] = useState([]);
  const [destination, setDestination] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [showBookDemo, setShowBookDemo] = useState(false);

  const apiUnivesity = process.env.REACT_APP_API_URL_UNIVERSITY;
  const apiCourse = process.env.REACT_APP_API_URL_COURSE;
  const apiDestination = process.env.REACT_APP_API_URL_DESTINATION;
  const ImgPath = process.env.REACT_APP_IMg_URL;

  useEffect(() => {
    fetchCourse();
    fetchUniversity();
    fetchDestination();
  }, []);
  const DivRef = useRef(null);
  useEffect(() => {
    if (DivRef.current) {
      DivRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, []);
  useEffect(() => {}, [selectedCountry]);

  const fetchCourse = async () => {
    try {
      const response = await axios.get(apiCourse);
      const CourseData = response.data;

      setCourseData(CourseData.reverse());
    } catch (error) {
    } finally {
    }
  };
  const fetchUniversity = async () => {
    try {
      const response = await axios.get(apiUnivesity);
      const CourseData = response.data;

      setUnivesity(CourseData.reverse());
      document.documentElement.style.setProperty(
        "--marquee-items",
        CourseData.length + 1
      );
      const itemsLogo = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--marquee-items");
    } catch (error) {
    } finally {
    }
  };

  const fetchDestination = async () => {
    try {
      const response = await axios.get(apiDestination);
      const DestinationData = response.data;

      setDestination(DestinationData.reverse());
      setSelectedCountry(DestinationData[0]);
    } catch (error) {
    } finally {
    }
  };

  const simpleFilterHandler = (filterData) => {
    navigate("/Explore-programs", { state: filterData });
  };

  const onCountrySelectdata = (data) => {
    setSelectedCountry(data);
    //   // navigate('/Destinations');
  };

  const BookDemoHandler = () => {
    setShowBookDemo(true);
  };
  // const cardsInfo = useRef(null);
  const FAQ = [
    {
      title: "What are the entry requirements for German universities?",
      content:
        "Entry requirements vary depending on the program and university. Generally, you need a recognized secondary school leaving certificate, proficiency in German or English (depending on the language of instruction), and sometimes specific subject prerequisites.",
    },
    {
      title: "Are there tuition fees for international students?",
      content:
        "Entry requirements vary depending on the program and university. Generally, you need a recognized secondary school leaving certificate, proficiency in German or English (depending on the language of instruction), and sometimes specific subject prerequisites.",
    },
    {
      title: "How do I apply for a program?",
      content:
        "Entry requirements vary depending on the program and university. Generally, you need a recognized secondary school leaving certificate, proficiency in German or English (depending on the language of instruction), and sometimes specific subject prerequisites.",
    },
    {
      title: "What scholarships are available for international students?",
      content:
        "Entry requirements vary depending on the program and university. Generally, you need a recognized secondary school leaving certificate, proficiency in German or English (depending on the language of instruction), and sometimes specific subject prerequisites.",
    },
    {
      title:
        "Are internships or practical experiences included in the curriculum?",
      content:
        "Entry requirements vary depending on the program and university. Generally, you need a recognized secondary school leaving certificate, proficiency in German or English (depending on the language of instruction), and sometimes specific subject prerequisites.",
    },
  ];
  ripples.register();

  const { scrollYProgress } = useScroll({
    target: migrate,
    offset: ["start start", "end start"],
  });
  const backgroundTwoY = useTransform(scrollYProgress, [0, 20], ["0%", "0%"]);

  return (
    <div ref={DivRef}>
      <div className="relative z-0">
        <Parallax></Parallax>
        <div className="overlay"></div>
      </div>
      <a
        className="Whats"
        href="https://wa.me/9188565665"
        target="_blank"
        tabindex="0"
      >
        <img className="" src={Whatsapp} alt="" />
      </a>
      <section>
        <div>
          <div className="pt-6 xl:pt-0">
            <div
              className="my-1 marquee marquee--8 "
              style={{ "--marquee-items": univesity.length }}
            >
              {univesity.map((item, index) => (
                <img
                  className="marquee__item"
                  src={ImgPath + item?.image}
                  width="100"
                  height="100"
                  alt=""
                  style={{ "--marquee-item-index": index + 1 }}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="bg-welcome ">
        <div className="main-section">
          <div className="grid grid-cols-1 sm:grid-cols-2">
            <div>
              <div className="relative flex w-10/12 pb-4 m-auto sm:w-10/12 sm:pb-0 ">
                <img className="" src={WelcomeBgOne} alt="" />

                <img className="absolute top-0 " src={WelcomeBgTwo} alt="" />
              </div>
            </div>
            <div className="flex items-end">
              <div>
                <div>
                  <h1 className="text-center thin-font sm:text-start">
                    Welcome to
                  </h1>
                  <h1 className="text-center blod-font sm:text-start">
                    Lambegus
                  </h1>
                </div>
                <p className="mt-3 text-base text-center sm:text-start">
                  Our goal is to empower students to achieve their global
                  academic aspirations, by providing them with the right
                  guidance and help them navigate their journey to prestigious
                  educational institutions worldwide, ensuring a hassle-free
                  transition and a truly enlightening academic experience.
                </p>

                <div>
                  <div className="hidden grid-cols-2 gap-5 xl:grid lg:grid-cols-4">
                    <div className="mt-4 text-center">
                      <img src={WelcomeCap} alt="" className="m-auto w-9 " />
                      <h4 className="small-b-text">302</h4>
                      <small className="normal-s-text">Universities</small>
                    </div>
                    <div className="mt-4 text-center">
                      <img
                        src={WelcomeUniversity}
                        alt=""
                        className="m-auto w-9"
                      />
                      <h4 className="small-b-text">10-40%</h4>
                      <small className="normal-s-text">University Grant</small>
                    </div>
                    <div className="mt-4 text-center">
                      <img src={WelcomeBooks} alt="" className="w-10 m-auto" />
                      <h4 className="small-b-text">2750</h4>
                      <small className="normal-s-text">Programs</small>
                    </div>
                    <div className="mt-4 text-center">
                      <img src={WelcomeHouse} alt="" className="m-auto w-9" />
                      <h4 className="small-b-text">800+</h4>
                      <small className="normal-s-text">Safe Homes</small>
                    </div>
                  </div>
                </div>
                <div className="text-center sm:text-start">
                  <NavLink
                    to="/Explore-programs"
                    className="mt-8 btn btn-primary"
                  >
                    Know More
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-5 mt-4 xl:hidden sm:grid-cols-4 ">
            <div className="mt-4 text-center">
              <img src={WelcomeCap} alt="" className="m-auto w-9 " />
              <h4 className="small-b-text">302</h4>
              <small className="normal-s-text">Universities</small>
            </div>
            <div className="mt-4 text-center">
              <img src={WelcomeUniversity} alt="" className="m-auto w-9" />
              <h4 className="small-b-text">10-40%</h4>
              <small className="normal-s-text">University Grant</small>
            </div>
            <div className="mt-4 text-center">
              <img src={WelcomeBooks} alt="" className="w-10 m-auto" />
              <h4 className="small-b-text">2750</h4>
              <small className="normal-s-text">Programs</small>
            </div>
            <div className="mt-4 text-center">
              <img src={WelcomeHouse} alt="" className="m-auto w-9" />
              <h4 className="small-b-text">800+</h4>
              <small className="normal-s-text">Safe Homes</small>
            </div>
          </div>
        </div>
      </section>
      <section className=" destinations migrate">
        <div className="w-auto pb-0 main-section sm:w-3/4">
          <h1 className="mb-0 text-center xl:mb-2 section-head sm:text-start">
            Destinations
          </h1>
          <p className="mb-4 text-base text-center sm:mb-0 sm:text-start">
            Pursue your academic dreams at the world's top universities with
            Lambegus.
          </p>
        </div>
        <div className="mt-16 sm:mt-0 scroll-section xl:mt-0">
          <SliderC
            countries={destination}
            onCountrySelect={onCountrySelectdata}
            design={false}
          />
        </div>
      </section>

      <section className="migrate">
        {selectedCountry == 0 ? (
          <div></div>
        ) : (
          <HomeDestinations destinationData={selectedCountry} />
        )}
      </section>

      <section className="laBg">
        <div className="main-section">
          <div className="grid grid-cols-1 lg:gap-4 xl:grid-cols-2 ">
            <div className="relative" ref={migrate}>
              <div className="top-0 right-0 z-30 sm:m-auto">
                <img src={languageOne} alt=""  className="m-auto sm:w-1/2 xl:w-auto"/>
              </div>
              <div className="absolute top-0 right-0 z-30 hidden xl:block">
                <img src={languageTwo} alt="" />
              </div>
            </div>

            <div className="flex flex-col justify-center sm:px-11 mt-9">
              <h1 className="text-center thin-font sm:text-start">
                Lambegus Academy
              </h1>

              <h5 className="my-5 text-center sm:my-7 language-p sm:text-start">
                a new initiative that offers language courses such as{" "}
                <span className="color">German and IELTS </span>
              </h5>
              <p className="text-center sm:text-start">
                Mastering a new language can be very challenging while pursuing
                education abroad, especially in European countries like Germany.
                Lambegus Academy offers German and IELTS (English) language
                courses. Our curriculum is designed with focus on all aspects of
                language training such as Listening, Reading. Speaking, and
                Writing to help students excel in their German Language and
                IELTS exams and succeed with flying colors, and achieve their
                academic goals internationally.
              </p>

              <div className="text-center sm:text-start">
                <button className="mt-8 btn btn-primary">Know More</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SimpleFilter
        dataCourse={courseData}
        dataUnivesity={univesity}
        onSetFinalData={simpleFilterHandler}
      />

      {/* <l-ripples
  size="45"
  speed="2" 
  color="#e453a6" 
></l-ripples> */}

      <section className="">
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4">
          <div className="px-4 py-6 gradientDrakOne lg:px-10 lg:py-14">
            <img
              className="w-3/12 m-auto xl:m-0 xl:w-2/5 sm:w-/12 sm:m-1"
              src={Accommodation}
              alt=""
            />
            <h5 className="mt-3 mb-3 text-xl font-bold text-center text-white sm:text-start lg:mb-6 lg:text-2xl">
              Accommodation
            </h5>
            <p className="text-base text-center text-white sm:text-start">
              Students who immigrate via us will be provided with proper
              relocation and accommodation facilities to ensure that they
              experience a hassle-free transition to living in Germany.
            </p>
          </div>
          <div className="px-4 py-6 gradientDrakTwo lg:px-10 lg:py-14">
            <img
              className="w-3/12 m-auto xl:m-0 xl:w-2/5 sm:w-/12 sm:m-1 "
              src={Insurance}
              alt=""
            />
            <h5 className="mt-3 mb-3 text-xl font-bold text-center text-white sm:text-start lg:mb-6 lg:text-2xl">
              Insurance
            </h5>
            <p className="text-base text-center text-white sm:text-start">
              We will ensure you have a safe stay in Germany with customized and
              tailor-made Insurance Plans for students.
            </p>
          </div>
          <div className="px-4 py-6 gradientDrakOne lg:px-10 lg:py-14">
            <img
              className="w-3/12 m-auto xl:m-0 xl:w-2/5 sm:w-/12 sm:m-1"
              src={AusbildungAssistance}
              alt=""
            />
            <h5 className="mt-3 mb-3 text-xl font-bold text-center text-white sm:text-start lg:mb-6 lg:text-2xl">
              Ausbildung Assistance
            </h5>
            <p className="text-base text-center text-white sm:text-start">
              Students can accomplish their academic and professional goals with
              our Ausbildung Service
            </p>
          </div>
          <div className="px-4 py-6 gradientDrakTwo lg:px-10 lg:py-14">
            <img
              className="w-3/12 m-auto xl:m-0 xl:w-2/5 sm:w-/12 sm:m-1"
              src={LawyerSupport}
              alt=""
            />
            <h5 className="mt-3 mb-3 text-xl font-bold text-center text-white sm:text-start lg:mb-6 lg:text-2xl">
              Lawyer Support
            </h5>
            <p className="text-base text-center text-white sm:text-start">
              Sail through legal challenges with ease with our Lawyer Service in
              Germany.
            </p>
          </div>
        </div>
      </section>

      {/* <FileUpload/> */}

      <section className="main-section">
        <h1 className="text-center section-head">Frequently asked questions</h1>
        <Accordion item={FAQ}></Accordion>
      </section>
      <ModalBook
        isVisible={showBookDemo}
        onClose={() => setShowBookDemo(false)}
      />
      <Footer />
    </div>
  );
};
