import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import { useLocation , useNavigate } from 'react-router-dom';

import { ProductCard } from "../components/ProductCard";
import { SideBar } from "../components/Finder/sidebar/SideBar";
import { Card } from "../components/Finder/card";
import { Footer } from "../components/Footer";
import Whatsapp from "../img/whatsapp.png";



export const ExplorePrograms = () => {
  const [allData, setAllData] = useState([]);
  const [searchFilter,setSearchFilter] = useState({})


  const [selectedCategory, setSelectedCategory] = useState(null);
  const [query, setQuery] = useState("");

  const [filteredData, setFilteredData] = useState([]);


  const navigate = useNavigate();
  const location = useLocation();


  


  const apiAll = process.env.REACT_APP_API_URL_ALL;


  useEffect(() => {
    fetchAll();
    setFilteredData(allData)

    const SearchfilterData = location.state;
    
    setSearchFilter (SearchfilterData)

    
    navigate(location.pathname, { replace: true, state: null });


  }, []);




  const fetchAll = async () => {
    try {
      const response = await axios.get(apiAll);
      const CourseData = response.data;

      const transformArray = (array) => {
        return array.flatMap(obj => 
          obj.sub_subject.split('#').map(sub => ({
            ...obj,
            sub_subject: sub.trim() + " "
          }))
        );
      };
      const transformedArray = transformArray(CourseData);
      setAllData(transformedArray.reverse());
      setFilteredData(transformedArray.reverse())

    } catch (error) {
    } finally {
    }
  };
  const handlerFilteredData = (data) => {
    setFilteredData(data);
  };

  
  const DivRef = useRef(null);
  useEffect(() => {
    if (DivRef.current) {
      DivRef.current.scrollIntoView({
          behavior: 'smooth'
      });
  }},[])

  // const handleChange = (event) => {
  //   setSelectedCategory(event.target.value);
  // };
  // const handlerCheck = (event) => {
  //   setSelectedCategory(event.target.value);
  // };

  // function filteredData(products, selected, query) {
  //   let filteredProducts = products;

  //   // selected  Input Items

  //   if (selected) {
  //     filteredProducts = filteredProducts.filter(
  //       ({ destination, univesity, sector, level, languages }) =>
  //         destination === selected ||
  //         univesity === selected ||
  //         sector === selected ||
  //         level === selected ||
  //         languages === selected
  //     );
  //   }

  //   return filteredProducts.map(
  //     ({ country, name, image, reviews, prevPrice, newPrice }) => (
  //       <Card
  //         key={Math.random()}
  //         img={image}
  //         // title={title}
  //         // star={star}
  //         // reviews={reviews}
  //         // prevPrice={prevPrice}
  //         // newPrice={newPrice}
  //       />
  //     )
  //   );
  // }

  // const result = filteredData(allData, selectedCategory, query);
  return (
    <div ref={DivRef}>
      <div className="page-banner bannerExplorePrograms">
        <h4 className="banner-title">
        Pursue Your Perfect Career Abroad        </h4>
      </div>
      <a
        className="Whats"
        href="https://wa.me/9188565665"
        target="_blank"
        tabindex="0" rel="noreferrer" 
      >
        <img className="" src={Whatsapp} alt="" />
      </a>


      <div className="main-section">
        <div className="grid grid-cols-2 lg:grid-cols-9">
          <div className="col-span-2">
            <SideBar allData={allData} onFilteredData={handlerFilteredData} searchFilterData={searchFilter} />
          </div>
          <div className="col-span-7">
            <ProductCard dataFilter={filteredData} />
          </div>
        </div>
      </div>

      <Footer />

    </div>
  );
};
