import React, { useState, useRef, useEffect } from 'react';

const DropdownLight = ({ dataCourse }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedItems((prevItems) =>
      prevItems.includes(option)
        ? prevItems.filter((item) => item !== option)
        : [...prevItems, option]
    );
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block w-full text-left" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className="flex justify-between block w-full p-1 text-base text-black placeholder-gray-400 bg-white border border-black rounded-xl"
          onClick={toggleDropdown}
        >
          <h6 className='single-line'>
            {selectedItems.length > 0 ? selectedItems.join(', ') : 'Select options'}
          </h6>
          <div className='mt-1'>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </div>
        </button>
      </div>

      {isOpen && (
        <div className="absolute right-0 w-full mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-[1] border-2">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <div
              className={`cursor-pointer flex items-center px-4 py-2 text-sm text-gray-600`}
              role="menuitem"
              onClick={() => handleOptionClick("all")}
            >
              <input
                type="checkbox"
                className="mr-2"
                checked={selectedItems.includes("all")}
                readOnly
              />
              All
            </div>
            {dataCourse.map(({ sub_subject }) => (
              <div
                key={sub_subject}
                className={`cursor-pointer flex items-center px-4 py-2 text-sm text-gray-600 ${selectedItems.includes(sub_subject) ? 'bg-slate-300' : ''
                  }`}
                role="menuitem"
                onClick={() => handleOptionClick(sub_subject)}
              >
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={selectedItems.includes(sub_subject)}
                  readOnly
                />
                {sub_subject}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownLight;
