import React, { useState, useRef } from "react";
import axios from "axios";

import IconLocation from "../icon/location.png";
import IconBag from "../icon/bag.png";
import SalaryIcon from "../icon/money.png";
import InfoIcon from "../icon/note.png";

const ModalCareer = ({ isVisible, onClose, children, item }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const fileInputRef = useRef(null);
  const [imageLink, setImageLink] = useState(null);
  const [message, setMessage] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [formDatas, setFormDatas] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    language: "",
    title:""
  });


  const onChangehandler = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const filUplaod = new FormData()
  //   filUplaod.append('file',selectedFile)
  //   console.log("filUplaod",filUplaod)
  //   const payload = {

  //         selectedFile: filUplaod,

  //         all:formDatas
  //       };

  //       console.log("payload",payload)
  //   try {
  //     const res = await axios.post("/apply.php", filUplaod);
  //   } catch (error) {
  //     console.error("Error sending data:", error);
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDatas((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = async (event) => {
    setIsChecked(event.target.checked);
  };

  if (!isVisible) return null;

  const handleClose = (e) => {
    if (e.target.id == "wrapper") onClose();
  };
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFileUploaded(false);
    setUploadProgress(0);
 
 
   
    
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setIsChecked(false)


    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('file2', JSON.stringify(formDatas));
    formData.append('title', JSON.stringify(item?.name));
    onClose()


    try {
      const response = await fetch('/apply.php', {
        method: 'POST',
        body: formData,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);

        },
      });

      if (response.ok) {
        setIsFileUploaded(true);
      } else {
        // Handle upload error
        console.error("Upload failed");
      }
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-25 backdrop-blur-sm"
      onClick={handleClose}
      id="wrapper"
    >
      <div className="w-[900px]  flex flex-col h-auto">
        <div className="modal-body">
          <div
            className="card-contact"
            style={{ overflowY: "auto", height: "75vh" }}
          >
            <div className="flex items-center justify-between ">
              <h4 className="bg-text">{item.name}</h4>
              <button
                className="text-3xl text-black place-self-end close-btn"
                onClick={() => onClose()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 22 22"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <form  onSubmit={handleUpload} >
              <div className="pb-3 border-[#ebebeb] border-b sm:pt-5 pt-2 ">
                <div className="flex flex-wrap gap-2 ">
                  <span className="flex gap-2 sm:mr-5">
                    <img className="object-none" src={IconBag} alt="" />
                    <small className="text-[#717B9E] font-medium">
                      {item.experience}
                    </small>
                  </span>
                  <span className="flex gap-2 sm:mr-5">
                    <img className="object-none" src={SalaryIcon} alt="" />
                    <small className="text-[#717B9E] font-medium">
                      {item.salary}
                    </small>
                  </span>
                  <span className="flex gap-2 sm:mr-5">
                    <img className="object-none" src={IconLocation} alt="" />
                    <small className="text-[#717B9E] font-medium">
                      {item.location}
                    </small>
                  </span>
                </div>
              </div>
              <div className="mt-2 ">
                <div className="grid grid-cols-1 gap-4 mt-0 sm:mt-3 lg:grid-cols-2">
                  <div className="mt-0 sm:mt-3">
                    <label className="block mb-1 ">Name</label>
                    <input
                      type="text"
                      name="name"
                      value={formDatas.name}
                      onChange={handleChange}
                      className="w-full p-2 border rounded-md"
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="mt-0 sm:mt-3">
                    <label className="block mb-1 ">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formDatas.email}
                      onChange={handleChange}
                      className="w-full p-2 border rounded-md"
                      placeholder="Enter Email"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4 mt-3 sm:mt-3 lg:grid-cols-2">
                  <div>
                    <label className="block mb-1 ">Phone</label>
                    <input
                      type="text"
                      name="phone"
                      value={formDatas.phone}
                      className="w-full p-2 border rounded-md"
                      placeholder="Enter Phone"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex flex-wrap items-center gap-2 mt-0 mb-3 sm:mt-3 ">
                    <label htmlFor="myfile">Enter the CV </label>

                    <input
                      type="file"
                      name="myfile"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>

                <div className="mt-0 sm:mt-3">
                  <label className="block mb-1 ">Introduce yourself</label>
                  <textarea
                    name="message"
                    value={formDatas.message}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md"
                    placeholder="Enter Message"
                  ></textarea>
                </div>
                <div className="flex gap-2 mt-0 sm:mt-3">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label className="">Language</label>
                </div>
                {isChecked && (
                  <div className="mt-0 sm:mt-3">
                    <label className="block mb-1 ">Language name </label>
                    <input
                      type="text"
                      name="language"
                      value={formDatas.language}
                      onChange={handleChange}
                      className="w-full p-2 border rounded-md"
                      placeholder="Enter lorem "
                    />
                  </div>
                )}

                <div className="mt-4 sm:mt-3">
                  {/* <button className="btn btn-primary" type="submit"   >
                    Submit
                  </button> */}
                  <button
                    className="btn-primary btn"
                    type="submit"
                    disabled={!selectedFile || isFileUploaded}
                  >
                    Upload
                  </button>
                  {uploadProgress > 0 && (
                    <p>Upload Progress: {uploadProgress}%</p>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCareer;
