import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Select from 'react-tailwindcss-select';
import 'tailwindcss/tailwind.css';
import Dropdown from "../MultiSelectDropdown";
const options = [
  { value: "fox", label: "🦊 Fox" },
  { value: "Butterfly", label: "🦋 Butterfly" },
  { value: "Honeybee", label: "🐝 Honeybee" }
];

export const SimpleFilter = (props) => {
  const [university, setuniversity] = useState([]);
  const [sort, setSort] = useState([]);
  const [sortCourse, setSortCouse] = useState([]);
  const [sortSubCourse, setSortSubCourse] = useState([]);
  const navigate = useNavigate();

  const [selectedDestination, setSelectedDestination] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedSubCourse, setSelectedSubCourse] = useState("");
  const [selectedSectore, setSelectedSector] = useState("");
  const [FinalData, setFinalData] = useState([]);


  
const [selectedOptions, setSelectedOptions] = useState([]);


  useEffect(() => {
    groupData();
  }, [props]);
  
 

  const groupData = () => {
    const grouped = Object.values(
      props.dataUnivesity.reduce((acc, item) => {
        const { country, name } = item;
        if (!acc[country]) {
          acc[country] = { country, name: [] };
        }
        acc[country].name.push(name);
        return acc;
      }, {})
    );
    setSort(grouped);
    // const destinationData = grouped.find(
    //   (item) => item.country === sort[0]?.country || ""
    // );
    // setuniversity(destinationData ? destinationData.name : []);
    // setSelectedDestination(
    //   destinationData ? destinationData.country : []
    // );
  };

  // useEffect(() => {
  //   const courseDatas = props.dataCourse;
  //   filterStreamFunction(courseDatas, selectedLevel);
  // }, [selectedLevel]);
  useEffect(() => {
    const transformArray = (array) => {
      return array.flatMap(obj => 
        obj.sub_subject.split('#').map(sub => ({
          ...obj,
          sub_subject: sub.trim() + " "
        }))
      );
    };
    const courseDatas = transformArray(props.dataCourse );
    filterSubStreamFunction(courseDatas, selectedCourse);
  }, [selectedCourse]);

  const handleDestinationChange = (event) => {
    setSortCouse([]);
    setSortSubCourse([]);
    setSelectedCourse("");
    setSelectedLevel("");

    const destination = event.target.value;
    setSelectedDestination(destination);

    const destinationData = sort.find((item) => item.country === destination);

    setuniversity(destinationData ? destinationData.name : []);
  };

  const handleLevelChange = (event) => {
    const level = event.target.value;
    setSelectedLevel(level);
    setSortCouse([]);
    setSortSubCourse([]);
    setSelectedCourse("");

    
    const transformArray = (array) => {
      return array.flatMap(obj => 
        obj.sub_subject.split('#').map(sub => ({
          ...obj,
          sub_subject: sub.trim() + " "
        }))
      );
    };
    const courseDatas = transformArray(props.dataCourse );

    filterStreamFunction(courseDatas, level);
  };
  const filterStreamFunction = (courseData, level) => {
    const filteredCourses = courseData.filter(
      (course) => course.level === level
    );
    const filteredUniversity = filteredCourses.filter((course) =>
      university.includes(course.university)
    );
    const uniqueUniversities = Array.from(
      new Set(filteredUniversity.map((item) => item.stream))
    );
    setSortCouse(uniqueUniversities);
  };
  const filterSubStreamFunction = (courseData, selectedCourse) => {
    const filteredSubCourses = courseData.filter(
      (course) => course.stream === selectedCourse
    );

    const uniqueSubCourses = Array.from(
      new Set(filteredSubCourses.map((item) => item.sub_subject))
    );

    setSortSubCourse(uniqueSubCourses);
  };
  const handleCourseChange = (event) => {
    const course = event.target.value;
    setSortSubCourse([])
    setSelectedCourse(course);

    
  };
  const handleSubCourseChange = (event) => {
    setSelectedSubCourse(event.target.value);
  };
  const handleSectorChange = (event) => {
    setSelectedSector(event.target.value);
  };
  const onHanderSerach = (event) => {
    const filterData = {
      Destination: selectedDestination,
      Level: selectedLevel,
      Stream: selectedCourse,
      SubStream: selectedSubCourse,
      Sectore: selectedSectore,
    };
    props.onSetFinalData(filterData);

    setFinalData(filterData);
  };

  return (
    <div>
      <section className="main-section">
        <div className="card-search-more">
          <div className="grid grid-cols-1 xl:grid-cols-[35%_65%] gap-7 xl:gap-8">
            <div className="flex flex-col justify-center">
              <h4 className="mb-4 text-2xl font-bold sm:text-4xl ">
                Search More
              </h4>
              <p>
                Let’s start your roadmap to success.Are you aiming for an
                Undergraduate or Master’s Degree, or a PhD in Europe? Find out
                what aligns with your interests. What field of study you would
                like to explore? What Industry you would like to work in? Just
                fill these up and we will find the best on for you.
              </p>
            </div>
            <div>
              <form className="grid items-end grid-cols-1 gap-4 px-0 xl:pr-8 sm:gap-6 sm:grid-cols-2 xl:px-12">
                <div>
                  <label
                    htmlFor="destination"
                    className="block mb-2 text-sm font-medium text-[#E6E6E6]"
                  >
                    Select destination
                  </label>
                  <select
                    id="destination"
                    className="text-base rounded-xl  block w-full p-2.5 bg-[#2F3043]  border-white placeholder-gray-400 text-white border"
                    value={selectedDestination}
                    onChange={handleDestinationChange}
                  >
                    <option value="" disabled>
                      Choose from options
                    </option>
                    {sort.map((item) => (
                      <option key={item.country} value={item.country}>
                        {item.country}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="education"
                    className="block mb-2 text-sm font-medium text-[#E6E6E6]"
                  >
                    Level of education
                  </label>
                  <select
                    id="destination"
                    className="text-base rounded-xl  block w-full p-2.5 bg-[#2F3043]  border-white placeholder-gray-400 text-white border"
                    onChange={handleLevelChange}
                    disabled={university == !university}
                    value={selectedLevel}
                  >
                    <option value="" disabled>
                      Choose from options
                    </option>
                    <option className="option" value="PG">
                      <label>PG</label>
                    </option>
                    <option value="UG">UG </option>
                    <option value="Doctorate">Doctorate</option>
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="stream"
                    className="block mb-2 text-sm font-medium text-[#E6E6E6]">
                    Select course 
                  </label>
                  <select
                    id="stream"
                    className="text-base rounded-xl  block w-full p-2.5 bg-[#2F3043]  border-white placeholder-gray-400 text-white border"
                    value={selectedCourse}
                    onChange={handleCourseChange}
                    disabled={sortCourse == !sortCourse }
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    <option value="all">
                      All
                    </option>

                    {sortCourse?.map((stream) => (
                      <option key={stream} value={stream}>
                        {stream}
                      </option>
                    ))}
                  </select>
                </div>
                {/* <div>
                  <label
                    htmlFor="Sector"
                    className="block mb-2 text-sm font-medium text-[#E6E6E6]"
                  >
                    Select course Sub stream
                  </label>
                  <select 
                    id="Sector"
                    className="text-base rounded-xl  block w-full p-2.5 bg-[#2F3043]  placeholder-gray-400 text-white border border-white"
                    disabled={sortSubCourse == ! sortSubCourse || sortCourse == "all" ? true : false  }
                    value={selectedSubCourse}
                    onChange={handleSubCourseChange}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {sortSubCourse?.map((sub_subject) => (
                      <option key={sub_subject} value={sub_subject}>
                        {sub_subject}
                      </option>
                    ))}
                  </select>
                </div> */}
                <div className="w-full">
               <label for="Sector" class="block mb-2 text-sm font-medium text-[#E6E6E6]">Select  Sub Sector</label>
               <Dropdown dataCourse={sortSubCourse} />
               </div>
                <div>
                  <label
                    htmlFor="Sector"
                    className="block mb-2 text-sm font-medium text-[#E6E6E6]"
                  >
                    Select Sector
                  </label>
                  <select
                    id="Sector"
                    className="text-base rounded-xl  block w-full p-2.5 bg-[#2F3043]  border-white placeholder-gray-400 text-white border"
                    value={selectedSectore}
                    onChange={handleSectorChange}
                    // disabled={selectedSubCourse === ""? true :false || sortCourse === "all" ? true :false }
                  >
                    <option>Choose from options</option>
                    <option value="all">All</option>
                    <option value="Private">Private</option>
                    <option value="Government">Government</option>
                  </select>
                </div>
               

                

                <div className="text-center sm:text-start">
                  <button className="btn btn-primary " onClick={onHanderSerach}>
                    Search
                  </button>
                  {/* <button className="btn btn-primary " onClick={onHanderSerach}>Search</button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
