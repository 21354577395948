import React, { useRef, useEffect, useState } from "react";
import { Footer } from '../components/Footer';

export const TermsConditions = () => {
    const DivRef = useRef(null);
    useEffect(() => {
      if (DivRef.current) {
        DivRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, []);
  
  return (
    
    <div ref={DivRef}>
      <div className="bg-gray-100">
    <div className="container px-4 py-8 pt-24 mx-auto sm:pt-28">
        <h1 className="text-center section-head ">Terms & Conditions</h1>

        <p className="mb-6 text-center">
          Lorem ipsum, or lipsum as it is sometimes known, is dummy text used
            in laying out print, graphic or web designs.
        </p>

        <h2 className="mt-8 mb-2 text-2xl font-bold"> 1. Lorem ipsum</h2>

  
        <p className="mb-4">
        Lorem ipsum, or lipsum as 
            web applications to respond to you as an individual. The web application can tailor its operations to your
            needs,
            likes, and dislikes by gathering and remembering information about your preferences.
        </p>

        <h2 className="mb-2 text-2xl font-bold">2. Lorem ipsum</h2>

        <p className="mb-4">
        Lorem ipsum, or lipsum as 
            web applications to respond to you as an individual. The web application can tailor its operations to your
            needs,
            likes, and dislikes by gathering and remembering information about your preferences.
        </p>

        <ul className="mb-4 list-disc list-inside">
            <li> Lorem ipsum, or lipsum as  keeping</li>
            <li> Lorem ipsum, or lipsum as  products and services</li>
            <li> Lorem ipsum, or lipsum as   , special offers, or other information which we think you
                may
                find
                interesting</li>
            <li> Lorem ipsum, or lipsum as  contact you for market research purposes. We may
                contact
                you by email, phone, or mail. We may use the information to customize the website according to your
                interests.</li>
        </ul>

        <h2 className="mb-2 text-2xl font-bold">3. Lorem ipsum</h2>

        <p className="mb-4">
        Lorem ipsum, or lipsum as  is secure. In order to prevent unauthorized access or
            disclosure,
            we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure the
            information we collect online.
        </p>

        <h2 className="mb-2 text-2xl font-bold">4.Lorem ipsum</h2>

        <p className="mb-4">
        Lorem ipsum, or lipsum as 
            web applications to respond to you as an individual. The web application can tailor its operations to your
            needs,
            likes, and dislikes by gathering and remembering information about your preferences.
        </p>

        <p className="mb-4">
        Lorem ipsum, or lipsum as . A cookie in no way gives us access to your computer or any information about you,
            other
            than
            the data you choose to share with us.
        </p>

        <h2 className="mb-2 text-2xl font-bold">5.Lorem ipsum</h2>

        <p className="mb-4">
        Lorem ipsum, or lipsum as.
            not governed by this privacy statement. You should exercise caution and look at the privacy statement
            applicable
            to
            the website in question.
        </p>

        <h2 className="mb-2 text-2xl font-bold">6. Lorem ipsum</h2>

        <p className="mb-4">
        Lorem ipsum, or lipsum as .or use of your personal information in the following ways:
        </p>

        <ul className="mb-4 list-disc list-inside">
            <li>  Lorem ipsum, or lipsum as  by law to do so. We may use your personal information to send you promotional
                information
                about
                third parties which we think you may find interesting if you tell us that you wish this to happen.</li>
            <li> Lorem ipsum, or lipsum as  details of personal information which we hold about you. If you would like a copy of the
                information held on you, please write to [Your Company Name, Address, City, State, Zip Code] or email
                [email protected]</li>
            <li> Lorem ipsum, or lipsum as . We will promptly correct any information found to be
                incorrect.
            </li>
        </ul>

        <p className="mb-4">
        Lorem ipsum, or lipsum as .
        </p>
    </div>

</div>
<Footer />

    </div>
  )
}


