import React, { useState, useRef } from "react";
import ModalHomeCard from "./ModalHomeCard"

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const IconSlider = ({ dataIcon, onCountrySelect, selectedCountry }) => {


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalItem, setModalItem] = useState(null);


  const openModal = (item) => {
    setModalItem(item);

    setIsModalVisible(true);
  };

  const closeModal = (item) => {
    setIsModalVisible(false);
    setModalItem(null);

  };


  const cardSlider = () => {
    alert("hello");
  };
  const ImgPath = process.env.REACT_APP_IMg_URL;

  // const ImgPath = process.env.REACT_APP_IMg_URL;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          variableWidth: false,
        },
      },
      {
        breakpoint: 	1280, // mobile breakpoint
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          variableWidth: false,
        },
      },
    ],
  };

  return (
    <div className=" mt-28 sm:mt-0">
      <Slider {...settings}>
        {dataIcon?.map((country) => (
          <div className="px-1 slider-pink-card">
            {country.iconName == "" ? (
              <div></div>
            ) : (
              <div className="mx-1 pink-card" onClick={() => openModal(country)}>
                <img src={ImgPath + country.icon} alt="" />
                <small className="mt-2 text-base text-center text-white">
                  {country.iconName}
                </small>
              </div>
            )}
          </div>
        ))}
      </Slider>

      <ModalHomeCard isVisible={isModalVisible} onClose={closeModal} item={modalItem}>
      </ModalHomeCard>
    </div>
  );
};
