// import React, { useRef ,useEffect,useState } from "react";
// import axios from "axios";
// import "./Nav.css";
// import AppBar from "@mui/material/AppBar";
// import Box from "@mui/material/Box";
// import CssBaseline from "@mui/material/CssBaseline";
// import Divider from "@mui/material/Divider";
// import Drawer from "@mui/material/Drawer";
// import IconButton from "@mui/material/IconButton";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemText from "@mui/material/ListItemText";
// import MenuIcon from "@mui/icons-material/Menu";
// import Toolbar from "@mui/material/Toolbar";
// import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";

// import { NavLink } from "react-router-dom";

// import logo from "../icon/logo.png";
// import ModalBook from "../components/BookModal";

// export const NavBar = (props) => {
//   const { window } = props;
//   const [mobileOpen, setMobileOpen] = React.useState(false);
//    const [destination,setDestination] = useState ([]);
//    const [showBookDemo, setShowBookDemo] = useState(false);

//    const apiDestination = process.env.REACT_APP_API_URL_DESTINATION;

//   const handleDrawerToggle = () => {
//     setMobileOpen((prevState) => !prevState);
//   };
//   useEffect (()=>{
//     fetchDestination()
//   },[])
//   const BookDemoHandler = () => {
//     setShowBookDemo(true);
//   };

//   const fetchDestination = async () => {
//     try {
//       const response = await axios.get(
//         apiDestination
//       );
//       const DestinationData = response.data;

//       setDestination(DestinationData.reverse());
//     } catch (error) {
//     } finally {
//     }

//   };

// const drawerWidth = 240;
// const navItems = [
//   { name: "Home", url: "/" },
//   { name: "About Us", url: "/About-us" },
//   { name: "Our Services ", url: "/OurServices" },
//   { name: "Explore Programs", url: "/Explore-programs" },
//   { name: "Destinations", url: `/Destinations/${destination[0]?.country}`},
//   { name: "Contact Us", url: "/Contact-us" },
// ];

//   const drawer = (
//     <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
//       <Divider />
//       <List>
//         {navItems.map((item) => (
//           <ListItem key={item.name} disablePadding>
//             <ListItemButton>
//               <NavLink to={item.url}>{item.name}</NavLink>
//             </ListItemButton>
//           </ListItem>
//         ))}
//       </List>
//     </Box>
//   );

//   const container =
//     window !== undefined ? () => window().document.body : undefined;

//   return (
//     // <div className="navbr">
//     //   <ul className="flex gap-8">
//     //     <li>
//     //       <NavLink to="/">
//     //         <img src={logo} alt="log" className="menu-logo" />
//     //       </NavLink>
//     //     </li>
//     //     <li>
//     //       <NavLink to="/About-us">About Us</NavLink>
//     //     </li>
//     //     <li>
//     //       <NavLink to="/Contact-us">Contact Us</NavLink>
//     //     </li>
//     //     <li>
//     //       <NavLink to="/Our-services">Our Services</NavLink>
//     //     </li>
//     //     <li>
//     //       <NavLink to="/Explore-programs">Explore Programs </NavLink>
//     //     </li>
//     //     <li>
//     //       <NavLink to="/Blogs">Blogs</NavLink>
//     //     </li>

//     //   </ul>

//     // </div>
//     <div className="main-nav ">
//       <AppBar component="nav" className="navbr z-100">
//         <Toolbar>
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             edge="start"
//             onClick={handleDrawerToggle}
//             sx={{ mr: 2, display: { sm: "none" } }}
//           >
//             <MenuIcon />
//           </IconButton>

//           <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//             <div className="flex items-center justify-between ">
//               <NavLink to="/">
//                 <img src={logo} alt="log" className="menu-logo" />
//               </NavLink>
//               <Box sx={{ display: { xs: "none", sm: "block" } }}>
//                 {navItems.map((item) => (
//                   <NavLink to={item.url}>{item.name}</NavLink>
//                 ))}
//               </Box>
//               <Box  sx={{ display: { xs: "none", sm: "block" } }}>
//                 <button className="btn btn-primary " onClick={BookDemoHandler}>Get in touch</button>
//               </Box>
//             </div>
//           </Typography>
//         </Toolbar>
//       </AppBar>
//       <nav  >
//         <Drawer
//           container={container}
//           variant="temporary"
//           anchor="right"
//           open={mobileOpen}
//           onClose={handleDrawerToggle}
//           ModalProps={{
//             keepMounted: true, // Better open performance on mobile.
//           }}
//           sx={{
//             display: { sm: "block", lg: "none" },
//             "& .MuiDrawer-paper": {
//               boxSizing: "border-box",
//               width: drawerWidth,
//             },
//           }}
//         >
//           {drawer}
//         </Drawer>
//       </nav>
//       <ModalBook
//         isVisible={showBookDemo}
//         onClose={() => setShowBookDemo(false)}
//       />
//     </div>
//   );
// };

import { useState, useEffect } from "react";
import logo from "../icon/logo.png";
import { NavLink } from "react-router-dom";
import "./navBar.css";
import ModalBook from "../components/BookModal";
import axios from "axios";

const apiDestination = process.env.REACT_APP_API_URL_DESTINATION;

export const NavBar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [smHide, setSmHide] = useState(true);
  const [selectedData, setSelectedData] = useState(null);
  const [showBookDemo, setShowBookDemo] = useState(false);
  const [destination, setDestination] = useState([]);

  useEffect(() => {
    fetchDestination();
  }, []);

  const fetchDestination = async () => {
    try {
      const response = await axios.get(apiDestination);
      const DestinationData = response.data;

      setDestination(DestinationData.reverse());
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1020) {

      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggle = () => {
    setToggleMenu(!toggleMenu);
    setSmHide(!smHide);
  };

  const closeMenu = () => {
    setToggleMenu(!toggleMenu);
    if (window.innerWidth <= 1020) {
      setSmHide(!smHide);
    }
  };

  const BookDemoHandler = () => {
    setShowBookDemo(true);
  };
  const handleClick = () => {
    window.location.href = 'https://lambegus.com/blog'; // Replace with your WordPress site URL
  };
  return (
    <div className="fixed top-0 w-full bg-white">
      <div
        className={
          smHide
            ? "nav px-6 lg:px-20  text-white flex  justify-between items-center"
            : "nav nav-sm "
        }
      >
        {smHide ? (
          <div>
            <NavLink to="/">
              <img className="menu-logo" src={logo} alt="log" />
            </NavLink>
          </div>
        ) : null}
        <div
          className={
            toggleMenu
              ? "lg:flex  lg:pt-0 pt-10 w-full lg:w-auto"
              : "hidden lg:flex"
          }
          id="menu"
        >
          <ul>
          <li className="px-3 py-2 border-b cursor-pointer lg:inline-block hover:text-gray-500 lg:border-none active">
              <NavLink to="/" onClick={closeMenu}>Home</NavLink>
            </li>
            <li className="px-3 py-2 border-b cursor-pointer lg:inline-block hover:text-gray-500 lg:border-none active">
              <NavLink to="/About-us" onClick={closeMenu}>About Us</NavLink>
            </li>
            <li className="px-3 py-2 border-b cursor-pointer lg:inline-block hover:text-gray-500 lg:border-none">
              <NavLink to="/OurServices" onClick={closeMenu}>Our Services</NavLink>
            </li>
            <li className="px-3 py-2 border-b cursor-pointer lg:inline-block hover:text-gray-500 lg:border-none">
              <NavLink to="/Explore-programs"onClick={closeMenu}>Explore Programs </NavLink>
            </li>
            <li className="px-3 py-2 border-b cursor-pointer lg:inline-block hover:text-gray-500 lg:border-none">
              <NavLink to="/Destinations" onClick={closeMenu}>
                Destinations
              </NavLink>
            </li>
            <li className="px-3 py-2 border-b cursor-pointer lg:inline-block hover:text-gray-500 lg:border-none">
              <NavLink to="https://lambegus.com/blog" onClick={handleClick}>
                Blogs
              </NavLink>
            </li>
            
            <li className="relative px-3 py-2 border-b cursor-pointer dropdown lg:inline-block hover:text-gray-500 lg:border-none">
              <NavLink to="/Contact-us" onClick={closeMenu}>Contact Us</NavLink>
            </li>
          </ul>
        </div>
        <div>
          <button onClick={BookDemoHandler} className="btn btn-primary hide-sm">
            Get in touch          
            </button>
        </div>
        <div className="cursor-pointer lg:hidden nav-menu">
          <input
            className="hidden menu-btn"
            type="checkbox"
            id="menu-btn"
            checked={toggleMenu}
            onChange={() => {}}
          />
          <label
            onClick={handleToggle}
            className="relative block px-2 py-4 cursor-pointer select-none menu-icon lg:hidden"
            htmlFor="menu-btn"
          >
            <span className="relative flex items-center navicon bg-white-darkest" ></span>
          </label>
        </div>
      </div>
      <ModalBook
        isVisible={showBookDemo}
        onClose={() => setShowBookDemo(false)}
      />
    </div>
  );
};
