import React, { useState, useRef } from "react";

const ModalHomeCard = ({ isVisible, onClose, children, item }) => {
  if (!isVisible) return null;

  const handleClose = (e) => {
    if (e.target.id == "wrapper") onClose();
  };
  const ImgPath = process.env.REACT_APP_IMg_URL;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-25 backdrop-blur-sm z-[101]"
      onClick={handleClose}
      id="wrapper"
    >
      <div className="w-[900px]  flex flex-col h-auto">
        <div className="modal-body">
          <div
            className="card-contact"
            style={{ overflowY: "auto", height: "75vh" }}
          >
            <div className="flex items-start justify-between ">
              <div className=" pink-card">
                <img src={ImgPath + item.icon} alt="" />
              </div>
              <div>
                <button
                  className="text-3xl text-black place-self-end close-btn"
                  onClick={() => onClose()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 22 22"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div>
              <div className="relative">
                <div className="mt-4">
                  <div>
                    <div className="flex flex-col gap-2">
                      <h3 className=" text-2xl font-bold text-[#00034d]">
                        {item.iconName}
                      </h3>
                    </div>

                    <p className="mt-6 text-base text-gray-600">
                     {item.iconDetails}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalHomeCard;
