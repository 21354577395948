import React, { useState, useRef, useEffect } from 'react';

const Dropdown = ({ dataCourse }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedItems((prevItems) =>
      prevItems.includes(option)
        ? prevItems.filter((item) => item !== option)
        : [...prevItems, option]
    );
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  console.log("dataCourse", dataCourse);

  return (
    <div className="relative inline-block w-full text-left" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className="text-base rounded-xl w-full p-2.5 bg-[#2F3043] border-white placeholder-gray-400 text-white border flex justify-between"
          onClick={toggleDropdown}
        >
          <h6 className="single-line">
            {selectedItems.length > 0 ? selectedItems.join(', ') : 'Select options'}
          </h6>
          <div className="mt-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
          </div>
        </button>
      </div>

      {isOpen && (
        <div className="absolute right-0 w-full mt-2 origin-top-right bg-[#2F3043] rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-[1]">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <div
              className={`cursor-pointer flex items-center px-4 py-2 text-sm text-white ${selectedItems.includes("all") ? 'bg-gray-600' : ''}`}
              role="menuitem"
              onClick={() => handleOptionClick("all")}
            >
              <input
                type="checkbox"
                className="mr-2"
                checked={selectedItems.includes("all")}
                readOnly
              />
              All
            </div>

            {dataCourse.map((option) => (
              <div
                key={option}
                className={`cursor-pointer flex items-center px-4 py-2 text-sm text-white ${selectedItems.includes(option) ? 'bg-gray-600' : ''}`}
                role="menuitem"
                onClick={() => handleOptionClick(option)}
              >
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={selectedItems.includes(option)}
                  readOnly
                />
                {option}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
