import React from 'react';
import { formatDistanceToNow, parse } from 'date-fns';

const DateAgo = ({ dateString }) => {
  const date = parse(dateString, 'dd-MM-yyyy', new Date());
  const timeAgo = formatDistanceToNow(date, { addSuffix: true });

  return (
    <div className='flex'>
      <small className='sm:mt-5 mt-2 text-sm text-[#717b9e]'>{timeAgo}</small>
    </div>
  );
};

export default DateAgo;