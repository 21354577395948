import React, { useState, useEffect } from "react";
import axios from "axios";

const ModalBook = ({ isVisible, onClose, children }) => {
  const [answer, setAnswer] = useState("yes");
  const [nearingYes, setNearingYes] = useState();
  const [nearingNo, setNearingNo] = useState();

  const [formData, setFormData] = useState({
    name: "",
    course:"",
    email: "",
    phone: "",
    message: "",
  });
  if (!isVisible) return null;

  const handleClose = (e) => {
    if (e.target.id == "wrapper") onClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {

    try {
      const res = await axios.post("/email.php", formData);
      onClose()

    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-25 backdrop-blur-sm z-[101]"
      onClick={handleClose}
      id="wrapper"
    >
      <div className="w-[900px]  flex flex-col h-auto">
        <div className="modal-body">
          <div className="card-contact">
            <div className="flex items-center justify-between ">
              <h4 className="bg-text">Drop us a line</h4>
              <button
                className="text-3xl text-black place-self-end close-btn"
                onClick={() => onClose()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 22 22"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div>
              <div>
                <div className="grid grid-cols-1 gap-4 mt-3 lg:grid-cols-2">
                  <div className="mt-3">
                    <label className="block mb-1 ">Name</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      required
                      onChange={handleChange}
                      className="w-full p-2 border rounded-md"
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="mt-3">
                    <label className="block mb-1 ">course</label>
                    <input
                      type="text"
                      name="course"
                      value={formData.course}
                      required
                      onChange={handleChange}
                      className="w-full p-2 border rounded-md"
                      placeholder="Enter Course Name"
                    />
                  </div>
                  <div>
                    <label className="block mb-1 ">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full p-2 border rounded-md"
                      placeholder="Enter Email"
                    />
                  </div>
                  <div>
                    <label className="block mb-1 ">Phone</label>
                    <input
                      type="text"
                      name="phone"
                      value={formData.phone}
                      required
                      onChange={handleChange}
                      className="w-full p-2 border rounded-md"
                      placeholder="Enter Phone"
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <label className="block mb-1 ">Message</label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md"
                    placeholder="Enter Message"
                  ></textarea>
                </div>

                <div className="mt-3">
                  <button className="btn btn-primary" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalBook;
