import React, { useRef, useEffect, useState } from "react";

import { removeDuplicates } from "../../../utils/removeDuplicates";

export const Country = ({ options, onChange, country, onCountry }) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);


  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onCountry(event.target.value);
  };

  const uniqueOptions = removeDuplicates(options);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      
    };
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  
    
  }, []);

  useEffect(()=>{
    if (screenWidth < 1024){
      setIsOpen(!isOpen);
    } 
  },[screenWidth])


  return (
    <div className="">
      <div>
      <div onClick={toggleAccordion} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', borderBottom: isOpen ? '1px solid #cccccc ' : '0px'  }} className="filter-subtitle">
      <h6 > Destination</h6>
        <div style={{ marginLeft: 'auto', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </div>
      {isOpen && <div>
        
        <from className="flex flex-col space-y-2 filter-inputs">
        <div className="inline-flex items-center mt-0">
          <label
            className="relative flex items-center p-2 rounded-full cursor-pointer"
            htmlFor="html"
          >
            <input
              type="radio"
              className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
              name="country"
              value=""
              checked={selectedValue === ""}
              onChange={handleChange}
            />
               <span className="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-3.5 w-3.5"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                >
                  <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                </svg>
              </span>
              </label>

           
            <label className="mt-px font-light text-gray-700 cursor-pointer select-none">
              All
            </label>
        </div>
        {uniqueOptions.map((option) => (
      
          <div className="inline-flex items-center mt-0">
            <label
              className="relative flex items-center p-2 rounded-full cursor-pointer"
              htmlFor="html"
            >
              <input
                type="radio"
                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                value={option.country}
                checked={selectedValue === option.country}
                onChange={handleChange}
                name={option.country}
                id={option.country}
              />
              <span className="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-3.5 w-3.5"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                >
                  <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                </svg>
              </span>
            </label>
            <label
              className="mt-px font-light text-gray-700 cursor-pointer select-none"
              htmlFor="html"
              for={option.country}
            >
              {option.country}
            </label>
          </div>
        ))}
      </from>
        </div>}
    </div>
    </div>
  );
};
