import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import "./Footer.css"

import logo from "../icon/white-logo.png";
import facbook from '../icon/facebook.png'
import twitter from '../icon/twitter.png';
import insta from '../icon/instagram.png';


export const Footer = () => {


    return (
        <div className='footer'>
            <div className='px-3 dark-bg p-t-b sm:px-5 xl:px-16'>
                <div className='grid grid-cols-2 gap-12 xl:gap-6 xl:grid-cols-6'>
                    <div className='col-span-2 px-1 footer-head ' >
                        <img src={logo} alt="log" className='logo' />
                        <p className='sm:pr-8'>Step into a bright and promising future with the Best Expat Service Provider in Europe. Your trusted expert for studying abroad in Germany.</p>
                    </div>
                    <div>
                        <h6 className='head'>Quick Menu</h6>
                        <ul>
                           
                            <li>
                                <NavLink to="/OurServices"  >Our Services</NavLink>
                            </li>
                            <li>
                                <NavLink  to ="/career" >Career</NavLink>
                            </li>
                            <li>
                            <NavLink to="/Explore-programs">Explore Programs</NavLink>
                            </li>
                            <li>
                            {/* <NavLink to="/Blogs">Our Blogs</NavLink> */}
                            </li>
                            <li>
                            <NavLink to="/About-us">About Us</NavLink>
                            </li>


                        </ul>
                    </div>
                    <div>
                        <h6 className='head'>Support </h6>

                        <ul>
                           
                            <li>
                                <NavLink to="/" >FAQ</NavLink>
                            </li>
                            <li>
                                <NavLink to="/Contact-us" >Contact Us</NavLink>
                            </li>
                           


                        </ul>
                    </div>
                    <div>
                        <h6 className='head'>Legal</h6>

                        <ul>
                        <li>
                                <NavLink to="/privacyPolicy" >Privacy Policy</NavLink>
                            </li>
                            <li>
                                <NavLink to="/terms&Condition">Terms & Conditions </NavLink>
                            </li>
                         

                        </ul>
                    </div>
                    <div className='flex flex-col gap-6'>
                        <h6 className='head'>Social Media</h6>

                        <div className='flex justify-start gap-5 px-4'>
                            <a href='#'><img src={facbook} alt="" /></a>
                            <a href='#'><img src={insta} alt="" /></a>
                            <a href='#'><img src={twitter} alt="" /></a>
                        </div>
                    </div>
                </div>
                <div className='copy'>
                    <small>© 2024 Lambegus. All rights reserved | Powered By: <a href='https://www.kreativesparkz.com/' target='_blank	'> Kreative Sparkz Ad Media </a> </small>

                </div>
            </div>

        </div>
    )
}
