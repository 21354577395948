import React, { useEffect, useState } from "react";
import { Card } from "./Finder/card";

export const ProductCard = ({dataFilter}) => {
  const [page, SetPage] = useState(1);
  const [productNo, setProductNo] = useState([]);

  useEffect (()=>{
    setProductNo(dataFilter)

    // const transformArray = (array) => {
    //   return array.flatMap(obj => 
    //     obj.sub_subject.split('#').map(sub => ({
    //       ...obj,
    //       sub_subject: sub.trim() + " "
    //     }))
    //   );
    // };
    // const transformedArray = transformArray(dataFilter);
    // setProductNo(dataFilter)

    setProductNo(shuffleArray(dataFilter))


  },[dataFilter])
  console.log("page",page)

  const shuffleArray = (array) => {
    let newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };





  const selectPageHandler = (selectedPage) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= Math.ceil(productNo.length / 10) &&
      selectedPage !== page
    )
      SetPage(selectedPage);
  };

  return (
    <div className="mt-4">
            <span className="text-2xl text-[#00034D] px-5"> <span className="font-bold "> {productNo.length}</span> <span className="font-medium " > results </span></span>

      {productNo.slice(page * 10 - 10, page * 10).map((productNo) => (
        <Card Name={productNo}   />
      ))}

      {productNo.length > 0 && (
        <div>
          <nav aria-label="Page navigation px-2 ">
            <ul className="inline-flex h-10 px-4 -space-x-px text-base">
              <li>
                <button
                  onClick={() => selectPageHandler(page - 1)}
                  className={`flex items-center justify-center h-10 px-4 leading-tight text-gray-500 bg-white border border-gray-300 ms-0 border-e-0 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 disabled:opacity-80 `}
                  disabled={page > 1 ? false: true}

               >
                  Previous
                </button>
              </li>
              {[...Array(Math.ceil(productNo.length / 10))].map((_, i) => {
                return (
                  <li>
                    <button
                      onClick={() => selectPageHandler(i + 1)}
                      className={`flex items-center justify-center h-10 px-4 leading-tight text-gray-500  border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${
                        page === i + 1 ? " bg-black" : "bg-white"
                      } `}
                    >
                      {i + 1}
                    </button>
                  </li>
                );
              })}

              <li>
                <button
                  onClick={() => selectPageHandler(page + 1)}
                  className="flex items-center justify-center h-10 px-4 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 disabled:opacity-80"  
                  disabled={page <= productNo.length / 10? false: true}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};
