import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { removeDuplicatesCourse } from "../utils/removeDuplicates";
import { IconSlider } from "./IconSlider";

import CVIcon from "../icon/requirements/CV.png";
import IELTSIcon from "../icon/requirements/IELTS.png";
import LORIcon from "../icon/requirements/LOR.png";
import MOIIcon from "../icon/requirements/MOI.png";
import PassportIcon from "../icon/requirements/Passport.png";
import UNIDocsIcon from "../icon/requirements/UNIDocs.png";

import EducationIcon from "../icon/Education.png";
import FeesIcon from "../icon/fees.png";
import OpportunitiesIcon from "../icon/Opportunities.png";
import bannerBbody from "../img/pages/Destinations/banner-body.png";

export const DestinationInfo = ({
  country,
  dataCourse,
  dataUnivesity,
  allData,
}) => {
  //   const apiDestination = process.env.REACT_APP_API_URL_DESTINATION;

  //   const { countryName } = useParams();
  // const [countryData, setCountry] = useState(null);

  // useEffect(() => {
  //   // Replace with your API URL
  //   fetch(`${apiDestination}`)
  //     .then(response => response.json())
  //     .then(data => setCountry(data));

  // }, [countryName])
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [filteredUniversitie,setFilteredUniversitie] = useState([])

  const uniqueOptions = removeDuplicatesCourse(dataCourse);





  useEffect(() => {
    const filteredUniversities = dataUnivesity.filter(
      (university) => university.country === country?.country
    );
    setFilteredUniversitie(filteredUniversities)
    const filtered = {};
    filteredUniversities.forEach((university) => {
      filtered[university.name] = allData.filter(
        (course) => course.university === university.name
      );
    });
    const courseNames = [];
    Object.keys(filtered).forEach((university) => {
      filtered[university].forEach((course) => {

        courseNames.push(course.course_name);
      });
    });
    setFilteredCourses(courseNames);

   
  }, []);



  const ImgPath = process.env.REACT_APP_IMg_URL;

  return (
    <div>
      <div className="pt-0 pb-0 main-section xl:pt-6">
        <div className="grid grid-cols-1 gap-0 xl:gap-12 xl:grid-cols-7 sm:mt-4 ">
          <div className="col-span-3 xl:h-[900px] xl:overflow-auto xl:sticky sm:top-20 xl:order-1 order-2 mt-8 xl:mt-0">
            <img
              src={ImgPath + country?.side_image}
              alt=""
              className="m-auto sm:mb-8"
            />

            <div>
              <h5 className="sub-pg-title ">Admission Requirements</h5>

              <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:gap-10">
                <div className="small-card">
                  <img src={PassportIcon} alt="" className=":w-[20%] xl:w-1/4 " />
                  <small>Passport</small>
                </div>
                <div className="small-card">
                  <img src={LORIcon} alt="" className="w-[20%] xl:w-1/4" />
                  <small>LOR</small>
                </div>
                <div className="small-card">
                  <img src={CVIcon} alt="" className="w-[20%] xl:w-1/4" />
                  <small>CV</small>
                </div>
                <div className="small-card">
                  <img src={UNIDocsIcon} alt="" className="w-[20%] xl:w-1/4" />
                  <small>UNI Docs</small>
                </div>
                <div className="small-card">
                  <img src={MOIIcon} alt="" className="w-[20%] xl:w-1/44" />
                  <small>MOI</small>
                </div>
                <div className="small-card">
                  <img src={IELTSIcon} alt="" className="w-[20%] xl:w-1/4" />
                  <small>IELTS</small>
                </div>
              </div>
            </div>
          </div>
          <div className="order-1 col-span-4 xl:sticky sm:top-20 xl:order-2">
            <div className="flex items-center gap-4 mt-8 sm:mt-4 ">
              <img src={ImgPath + country?.logo} alt="" className="w-12 h-12" />

              <h5 className="text-3xl font-bold text-[#00034D]">
                {country?.country}
              </h5>
            </div>
            <div>
              <p className="mt-6">{country.about_country}</p>
            </div>
            <div className="mt-3">
              <h6 className="sub-pg-title">
                Benefits of Studying in
                <span className="text-[#B10C47]"> {country?.country}</span>
              </h6>

              <div>
                <img src={EducationIcon} alt="" />
                <h6 className="small-head">High Quality Education</h6>
                <p>{country.high_quality}</p>
              </div>
              <div className="mt-8">
                <img src={FeesIcon} alt="" />
                <h6 className="small-head">
                  Tution fees (Affordable - Public & Private)
                </h6>
                <p>{country?.tution_fees}</p>
              </div>
              <div className="mt-8">
                <img src={OpportunitiesIcon} alt="" />
                <h6 className="small-head">Opportunities </h6>
                <p>{country?.tution_fees}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="relative mt-12 ">
          <img
            className="rounded-2xl sm:h-auto h-28"
            src={bannerBbody}
            alt=""
          />
          <h6 className="absolute bottom-4 left-4 sm:bottom-10 sm:left-10 text-[#E6E6E6] sm:text-5xl text-2xl font-light">
            Universities <br /> Details
          </h6>
        </div>

        <div className="grid grid-cols-1 gap-0 mt-5 sm:gap-12 sm:grid-cols-7">
          <div className="col-span-2">
            <h5 className="sub-pg-title ">Leading Universities</h5>
          </div>
          <div className="col-span-5">
            <p>{country.lead_uni}</p>
            <div className="flex flex-wrap gap-2 mt-2 ">
              {filteredUniversitie.map((item) => (
                <label className="border border-[#CCCCCC] px-3 py-1 rounded-full text-[14px]">
                  {item.name}
                </label>
              ))}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-0 mt-5 mb-12 sm:gap-12 sm:grid-cols-7">
          <div className="col-span-2">
            <h5 className="sub-pg-title ">Popular Courses</h5>
          </div>
          <div className="col-span-5">
            <p>{country.pop_course}</p>
            <div className="flex flex-wrap gap-2 mt-2 mb-4 ">
              {filteredCourses.map((name, index) => (
                <label className="border border-[#CCCCCC] px-3 py-1 rounded-full text-[14px]">{name}</label>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
