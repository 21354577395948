import React, { useRef, useEffect, useState } from "react";
import { Footer } from "../components/Footer";
import { ReadMore } from "../utils/ReadMore";
import Expertise from "../icon/about/expertise.png";
import studentIcon from "../icon/migrate/student.png";
import LanguageVisa from "../icon/migrate/LanguageVisa.png";

import FamilyReunIcon from "../icon/migrate/FamilyReunion.png";
import JobSeekerIcon from "../icon/migrate/JobSeeker.png";
import SkilledWorkerIcon from "../icon/migrate/SkilledWorker.png";
import BlueCardIcon from "../icon/migrate/BlueCard.png";

import Accommodation from "../icon/features/Accommodation.png";
import AusbildungAssistance from "../icon/features/AusbildungAssistance.png";
import Insurance from "../icon/features/Insurance.png";
import LawyerSupport from "../icon/features/LawyerSupport.png";
import Whatsapp from "../img/whatsapp.png";

export const OurServices = () => {
  const DivRef = useRef(null);
  useEffect(() => {
    if (DivRef.current) {
      DivRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <div ref={DivRef}>
      <div className="page-banner bannerServices">
        <h4 className="banner-title">Our Services</h4>
      </div>
      <a
        className="Whats"
        href="https://wa.me/9188565665"
        target="_blank"
        tabindex="0"
      >
        <img className="" src={Whatsapp} alt="" />
      </a>

      <section className="py-12 sm:py-12 lg:py-16 bg-welcomeThree">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          {/* <p className="text-center">
            Lorem ipsum, or lipsum as it is sometimes known, is dummy text used
            in laying out print, graphic or web designs. The passage is
            attributed to an unknown typesetter in the 15th century who is
            thought to have scrambled parts of Cicero's De Finibus Bonorum et
            Malorum for use in a type specimen book. It usually begins with
          </p> */}
          <div className="grid max-w-4xl grid-cols-1 mx-auto mt-8 text-left lg:max-w-6xl gap-y-4 sm:gap-x-8 sm:grid-cols-2 sm:mt-12 lg:mt-20">
            <div className="relative">
              <div className=" white-card-icon">
                <div className="p-2">
                  <div className="flex flex-col gap-2">
                    <img className="w-20 icon-bg " src={studentIcon} alt="" />

                    <h3 className=" text-2xl font-bold text-[#00034d]">
                      Student Visa
                    </h3>
                  </div>

                  <p className="mt-6 text-base text-gray-600">
                  Lambegus offers assistance in obtaining student visas for public
and   private   universities   in   European   countries,   especially
Germany, Austria, and France. Our services include access to an
extensive database of university courses, loan assistance, and visa
guidance.  We also offer support for Language training such as
German and IELTS which enhance your possibilities of success in
securing   admission.  With   Lambegus,   you   can   be   confident   in
personalized support throughout your study abroad journey.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative">
              <div className="absolute -inset-1"></div>
              <div className="relative h-full overflow-hidden white-card-icon ">
                <div className="p-2">
                  <div className="flex flex-col gap-2">
                    <img className="w-20 icon-bg " src={LanguageVisa} alt="" />

                    <h3 className=" text-2xl font-bold text-[#00034d]">
                      Language Visa
                    </h3>
                  </div>

                  <p className="mt-6 text-base text-gray-600">
                  Lambegus   guides   in   securing   Language   Visas,   which   allow
students to immerse in foreign language study programs abroad.
Our expert team will walk you through the entire application
process ensuring all the document and visa requirements are met.
We will assist the students in choosing the right language schools
and courses that suit their specific needs, particularly for countries
where a good grasp of the language is pre-requisite; Germany,
France, and Spain. Lambegus also helps students in preparing for
language proficiency tests. With our support and guidance, you can
embark on an enriching educational experience abroad.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative">
              <div className="absolute -inset-1"></div>
              <div className="relative h-full overflow-hidden white-card-icon ">
                <div className="p-2">
                  <div className="flex flex-col gap-2">
                    <img className="w-20 icon-bg " src={LanguageVisa} alt="" />

                    <h3 className=" text-2xl font-bold text-[#00034d]">
                      Ausbildung Visa
                    </h3>
                  </div>

                  <p className="mt-6 text-base text-gray-600">
                  We specialize in obtaining Ausbildung Visas for a wide variety of
courses such as Healthcare, Information Technology, Logistics,
Mechanical, Civil, and Management. We assist in helping students
identify the right Ausbildung programs with reputed companies
that   allow   growth   as   well   as   good   stipend   and   placement
opportunities. With our unwavering support, you can confidently
pursue your Ausbildung course in Germany.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative">
              <div className="absolute -inset-1"></div>
              <div className="relative h-full overflow-hidden white-card-icon ">
                <div className="p-2">
                  <div className="flex flex-col gap-2">
                    <img
                      className="w-20 icon-bg "
                      src={SkilledWorkerIcon}
                      alt=""
                    />

                    <h3 className=" text-2xl font-bold text-[#00034d]">
                      Skilled Worker Visa{" "}
                    </h3>
                  </div>

                  <p className="mt-6 text-base text-gray-600">
                  Lambegus also provides extensive assistance for Skilled Worker
Visas. We offer tailored advice on eligibility criteria, job search
strategies, and employer sponsorships, helping you to mitigate the
complexities of securing a Skilled Worker Visa. Our goal is to
facilitate   a   smooth   and   efficient   process   that   enables   skilled
individuals to join the workforce abroad with confidence. Whether
you need help with initial applications, renewals, or transitioning
to   permanent   residency,   Lambegus   supports   your   journey
throughout.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative">
              <div className="absolute -inset-1"></div>
              <div className="relative h-full overflow-hidden white-card-icon ">
                <div className="p-2">
                  <div className="flex flex-col gap-2">
                    <img
                      className="w-20 icon-bg "
                      src={FamilyReunIcon}
                      alt=""
                    />

                    <h3 className=" text-2xl font-bold text-[#00034d]">
                      Family Reunion Visa
                    </h3>
                  </div>

                  <p className="mt-6 text-base text-gray-600">
                  Lambegus is dedicated to supporting our clients in every step of
their journey; therefore, we offer Family Reunion Visa assistance
for students and workers who are already living abroad, ensuring
that their loved ones can join them seamlessly without any hassles.
Moreover, as a gesture of our commitment to client satisfaction,
we do not charge any additional fees for processing family reunion
visas for our previous clients. Your family's happiness and well-
being will always be our top priority.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative">
              <div className="absolute -inset-1"></div>
              <div className="relative h-full overflow-hidden white-card-icon ">
                <div className="p-2">
                  <div className="flex flex-col gap-2">
                    <img className="w-20 icon-bg " src={JobSeekerIcon} alt="" />

                    <h3 className=" text-2xl font-bold text-[#00034d]">
                      Job seeker Visa
                    </h3>
                  </div>

                  <p className="mt-6 text-base text-gray-600">
                  At Lambegus Abroad Consultancy, we offer extensive support to
individuals with job-seeker 
visas. Our services are designed to support clients in navigating
the complexities of obtaining a job seeker visa, ensuring they get
the right guidance and resources to pursue career opportunities
abroad. Whether you're exploring new job prospects or seeking to
advance your career globally, we are here to support you with
personalized assistance throughout the visa application process. 
                  </p>
                </div>
              </div>
            </div>
            <div className="relative">
              <div className="absolute -inset-1"></div>
              <div className="relative h-full overflow-hidden white-card-icon ">
                <div className="p-2">
                  <div className="flex flex-col gap-2">
                    <img className="w-20 icon-bg " src={BlueCardIcon} alt="" />

                    <h3 className=" text-2xl font-bold text-[#00034d]">
                      Blue Card
                    </h3>
                  </div>

                  <p className="mt-6 text-base text-gray-600">
                  We assist clients with obtaining a Blue Card visa. The Blue Card is a
work permit designed for highly skilled non-EU citizens seeking
employment in Europe, providing a streamlined path to work and
reside in participating countries. We offer guidance to navigate the
application process, ensuring clients meet the eligibility criteria
and compile the necessary documents to secure a great job in
Europe.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative">
              <div className="absolute -inset-1"></div>
              <div className="relative h-full overflow-hidden white-card-icon ">
                <div className="p-2">
                  <div className="flex flex-col gap-2">
                    <img className="w-20 icon-bg " src={BlueCardIcon} alt="" />

                    <h3 className=" text-2xl font-bold text-[#00034d]">
                      Opportunity Card
                    </h3>
                  </div>

                  <p className="mt-6 text-base text-gray-600">
                  This visa, also known as the "Aufenthaltserlaubnis zur Suche eines
Arbeitsplatzes"   (residence   permit   to   seek   employment),   is
designed for qualified non-EU citizens who wish to search for
employment opportunities in Germany. We guide clients through
the   application   process,   ensuring   they   understand   the
requirements and gather the necessary documents. Whether you
are   exploring   career   prospects   or   seeking   to   advance
professionally in Germany, Lambegus is committed to facilitating
your journey with expert assistance.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 px-1 mt-8 text-left sm:px-8 ">
            <div className="relative ">
              <div className="absolute -inset-1"></div>
              <div className="relative h-full overflow-hidden white-card-icon ">
                <div className="p-2">
                  <div className="flex flex-col gap-2">
                    <img className="w-20 icon-bg " src={BlueCardIcon} alt="" />

                    <h3 className=" text-2xl font-bold text-[#00034d]">
                      Aupair visa
                    </h3>
                  </div>

                  <p className="mt-6 text-base text-gray-600">
                  The Au Pair visa is designed for young adults from outside the
European Union who wish to live with a German host family, assist
with childcare, and experience cultural exchange. Our consultancy
will   walk   you   through   the   Au   Pair   visa   application   process
smoothly, ensuring clients meet all requirements and submit the
required documents. Whether you are eager to immerse yourself in
German culture, improve your language skills, or gain valuable
childcare experience abroad, Lambegus is dedicated to supporting
your journey with expertise and care.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

   
      <section className="">
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4">
          <div className="px-4 py-6 gradientDrakOne lg:px-10 lg:py-14">
            <img
              className="w-3/12 m-auto xl:m-0 xl:w-2/5 sm:w-/12 sm:m-1"
              src={Accommodation}
              alt=""
            />
            <h5 className="mt-3 mb-3 text-xl font-bold text-center text-white sm:text-start lg:mb-6 lg:text-2xl">
              Accommodation
            </h5>
            <p className="text-base text-center text-white sm:text-start">
              Students who immigrate via us will be provided with proper
              relocation and accommodation facilities to ensure that they
              experience a hassle-free transition to living in Germany.
            </p>
          </div>
          <div className="px-4 py-6 gradientDrakTwo lg:px-10 lg:py-14">
            <img
              className="w-3/12 m-auto xl:m-0 xl:w-2/5 sm:w-/12 sm:m-1"
              src={Insurance}
              alt=""
            />
            <h5 className="mt-3 mb-3 text-xl font-bold text-center text-white sm:text-start lg:mb-6 lg:text-2xl">
              Insurance
            </h5>
            <p className="text-base text-center text-white sm:text-start">
              We will ensure you have a safe stay in Germany with customized and
              tailor-made Insurance Plans for students.
            </p>
          </div>
          <div className="px-4 py-6 gradientDrakOne lg:px-10 lg:py-14">
            <img
              className="w-3/12 m-auto xl:m-0 xl:w-2/5 sm:w-/12 sm:m-1"
              src={AusbildungAssistance}
              alt=""
            />
            <h5 className="mt-3 mb-3 text-xl font-bold text-center text-white sm:text-start lg:mb-6 lg:text-2xl">
              Ausbildung Assistance
            </h5>
            <p className="text-base text-center text-white sm:text-start">
              Students can accomplish their academic and professional goals with
              our Ausbildung Service
            </p>
          </div>
          <div className="px-4 py-6 gradientDrakTwo lg:px-10 lg:py-14">
            <img
              className="w-3/12 m-auto xl:m-0 xl:w-2/5 sm:w-/12 sm:m-1"
              src={LawyerSupport}
              alt=""
            />
            <h5 className="mt-3 mb-3 text-xl font-bold text-center text-white sm:text-start lg:mb-6 lg:text-2xl">
              Lawyer Support
            </h5>
            <p className="text-base text-center text-white sm:text-start">
              Sail through legal challenges with ease with our Lawyer Service in
              Germany.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
