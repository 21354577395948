import React, { useRef, useEffect, useState } from "react";
import Checkbox from "../Checkbox";

import { removeDuplicatesUniversity } from "../../../utils/removeDuplicates";

import "../input.css";

export const Level = ({ options, onChange, country, onLevel }) => {
  const initialLevel = [
    { label: "UG", checked:false, key: "UG" },
    { label: "PG ", checked: false, key: "PG" },
    { label: "Doctorate", checked: false, key: "Doctorate" },
  ];

  const [checkboxes, setCheckboxes] = useState(initialLevel);
  const [selectedKey, setSelectedKey] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);


  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  // const handleChange = (event) => {
  //   setSelectedValue(event.target.value);
  //   onSector(event.target.value);
  // };

  const handleCheckboxChange = (label, checked, key) => {
    const updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.label === label ? { ...checkbox, checked: checked } : checkbox
    );
    setCheckboxes(updatedCheckboxes);

    const filteredKey = updatedCheckboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.key);

    setSelectedKey(filteredKey);
    onLevel(filteredKey);
  };
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);

    };
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(()=>{
    if (screenWidth < 1024){
      setIsOpen(!isOpen);
    } 
  },[screenWidth])
  return (
    <div className="">
    



      <div>
      <div onClick={toggleAccordion} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', borderBottom: isOpen ? '1px solid #cccccc ' : '0px' }}className="filter-subtitle">
      <h6 > Level</h6>
        <div style={{ marginLeft: 'auto', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </div>
      {isOpen && <div>  <div className="filter-inputs">
        {checkboxes.map((checkbox) => (
          <Checkbox
            label={checkbox.label}
            checked={checkbox.checked}
            onChange={handleCheckboxChange}
          />
        ))}
      </div></div>}
    </div>
    </div>
  );
};
